import {
    DEVICES_GET_REQUEST,
    DEVICES_GET_SUCCESS,
    // DEVICES_GET_FAILURE,
    DEVICES_WAKEUPTIMES_UPDATE_SUCCESS,
    DEVICES_WAKEUPTIMES_UPDATE_REQUEST,
    DEVICES_WAKEUPDAYS_UPDATE_SUCCESS,
    DEVICE_UPDATE_REQUEST,
    DEVICE_UPDATE_SUCCESS,
    DEVICE_REMOVE_REQUEST,
    DEVICE_REMOVE_SUCCESS,
    DEVICE_REMOVE_FAILURE,
    LOCATIONS_GET_REQUEST,
    LOCATIONS_GET_SUCCESS,
    LAST_LOCATIONS_GET_SUCCESS,
    ADDRESS_GET_SUCCESS,
    ADDRESS_GET_REQUEST,
    ADDRESS_GET_FAILURE,
    DEVICE_ADD_REQUEST,
    DEVICE_ADD_SUCCESS,
    DEVICE_ADD_FAILURE,
    CLEAR_DEVICE_ERRORS,
    LAST_WAKEUPS_GET_REQUEST,
    LAST_WAKEUPS_GET_SUCCESS,
    LAST_WAKEUPS_GET_FAILURE,
    DEVICE_GET_RECOVERY_CODE_REQUEST,
    DEVICE_GET_RECOVERY_CODE_FAILURE,
    DEVICE_GET_RECOVERY_CODE_SUCCESS,
    DEVICE_CLEAR_RECOVERY_CODE_REQUEST,
    DEVICE_CLEAR_RECOVERY_CODE_SUCCESS,
    DEVICE_CLEAR_RECOVERY_CODE_FAILURE,
    DEVICES_GET_INSTALLATION_HISTORY_REQUEST,
    DEVICES_GET_INSTALLATION_HISTORY_SUCCESS,
    DEVICES_GET_INSTALLATION_HISTORY_FAILURE,
    LOGOUT,
    DEVICES_GET_NOTES_REQUEST,
    DEVICES_GET_NOTES_SUCCESS,
    DEVICES_GET_NOTES_FAILURE,
    DEVICES_SET_NOTE_REQUEST,
    DEVICES_SET_NOTE_SUCCESS,
    DEVICES_SET_NOTE_FAILURE,
    UPDATE_DEVICE_TAGS_REQUEST,
    UPDATE_DEVICE_TAGS_SUCCESS,
    UPDATE_DEVICE_TAGS_FAILURE,
    DEVICES_WAKEUPDAYS_UPDATE_REQUEST,
    DEVICES_CLEARALERT_REQUEST,
    DEVICES_CLEARALERT_SUCCESS,
    DEVICES_CLEARALERT_FAILURE,
    DEVICES_SET_FAVOURITE_REQUEST,
    DEVICES_SET_FAVOURITE_FAILURE,
    DEVICES_SET_FAVOURITE_SUCCESS,
    COUNT_TAG_REQUEST,
    COUNT_TAG_SUCCESS,
    COUNT_TAG_FAILURE,
    COUNT_FILTER_REQUEST,
    COUNT_FILTER_SUCCESS,
    COUNT_FILTER_FAILURE,
    DEVICES_SELECT_ALL_REQUEST,
    DEVICES_SELECT_ALL_SUCCESS,
    DEVICES_SELECT_ALL_FAILURE,
    LAST_LOCATIONS_GET_REQUEST,
    PROXIMITY_GET_REQUEST,
    PROXIMITY_GET_FAILURE,
    PROXIMITY_GET_SUCCESS
} from '../actiontypes/types';

const initialState = {
    devices: [],
    documentCount: 0,
    deviceInstallers: [],
    locationsView: [],
    lastLocationsView: [],
    lastWakeups: [],
    proximities: [],
    filterCounts: {},
    isLoadingDevices: true,
    isUpdatingTimes: false,
    isUpdatingDevice: false,
    isGettingAddress: false,
    isGettingLastWakeups: false,
    isGettingRecoveryCode: false,
    isRemovingDevice: false,
    isClearingDeviceAlert: false,
    isSettingFavourite: false,
    isGettingInstallationHistory: false,
    isGettingFilterCounts: false,
    addingNote: false,
    addNoteError: null,
    isSelectingAll: false,
    isGettingProximity: false,
    history: {},
    installers: [],
    notes: {},
    notesUsers: [],
    balances: {},
    startingBalance: null,
    error: null
};

const selectAllHandler = (body, devices) => {
    switch(body.type) {
        case 'wakeupThreshold':
            return devices.map(device => ({...device, wakeupCountThreshold: body.wakeupCountThreshold}));
        case 'clearAlert':
            return devices.map(device => ({ ...device, alert: null, alertDate: null}));
        case 'wakeupTimes':
            return devices.map(device => ({ ...device, pendingWakeupTimes: body.times, pendingWakeupDays: body.wakeupDays}));
        case 'tamper':
            return devices.map(device => ({ ...device, pendingTamperState: body.tamperState}));
        case 'operatingMode':
            return devices.map(device => ({ ...device, pendingOperatingMode: body.operatingMode}));
        case 'group':
            return devices.map(devices => ({ ...devices, groupId: body.groupId}));
        case 'tags':
            return devices.map(device => ({ ...device, tags: body.tags}));
        default: return devices;
    }
}

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case DEVICES_SELECT_ALL_REQUEST:
            return {
                ...state,
                isSelectingAll: true,
            }
        case DEVICES_SELECT_ALL_FAILURE:
            return {
                ...state,
                isSelectingAll: false,
                error: payload
            }
        case DEVICES_SELECT_ALL_SUCCESS:
            const selectAllDevices = selectAllHandler(payload.result, state.devices);
            return {
                ...state,
                isSelectingAll: false,
                devices: selectAllDevices
            }
        case ADDRESS_GET_REQUEST:
            return {
                ...state,
                isGettingAddress: true
            };
        case ADDRESS_GET_FAILURE:
            return {
                ...state,
                isGettingAddress: true
            };
        case ADDRESS_GET_SUCCESS:
            return {
                ...state,
                isGettingAddress: false,
                locationsView: state.locationsView.map(view => {
                    return view._id === payload._id ? { ...view, address: payload.response.results[0].formatted_address } : view;
                })
            };
        case DEVICES_GET_REQUEST:
            return {
                ...state,
                devices: [],
                isLoadingDevices: true
            };
        case DEVICES_GET_SUCCESS:
            return {
                ...state,
                devices: payload.units,
                documentCount: payload.documentCount,
                deviceInstallers: payload.installers,
                isLoadingDevices: false
            };
        case LOCATIONS_GET_SUCCESS:
            const locationsView = [];

            payload.distinctIds.forEach(_id => {
                const locationObj = { _id };
                locationObj.data = payload.devices.find(device => device._id === _id);
                locationObj.name = locationObj.data.name;
                locationObj.wakeups = payload.locations.filter(device => device.deviceId === _id);
                locationsView.push(locationObj);
            });

            return {
                ...state,
                locationsView,
                isGettingLastWakeups: false
            };
        case LOCATIONS_GET_REQUEST:
            return {
                ...state,
                isGettingLastWakeups: true,
            }
        case LAST_LOCATIONS_GET_REQUEST:
            return {
                ...state,
                isLoadingDevices: true
            };
        case LAST_LOCATIONS_GET_SUCCESS:
            const lastLocationsView = [];
            const balances = {};

            payload.locations.forEach(location => {
                const locationObj = { _id: location._id };

                locationObj.data = payload.devices.find(device => device._id === location._id);
                locationObj.name = locationObj.data && locationObj.data.name;
                locationObj.wakeups = [location];
                lastLocationsView.push(locationObj);
            });

            payload.balance.counts && payload.balance.counts.forEach(count => {
                balances[count._id] = count.used;
            });

            const startingBalance = payload.balance.startingBalance ? Number(payload.balance.startingBalance) : null;

            return {
                ...state,
                lastLocationsView,
                devices: payload.devices,
                documentCount: payload.documentCount,
                balances,
                startingBalance,
                isLoadingDevices: false,
            };
        case LAST_WAKEUPS_GET_REQUEST:
            return {
                ...state,
                isGettingLastWakeups: true
            };
        case LAST_WAKEUPS_GET_FAILURE:
            return {
                ...state,
                isGettingLastWakeups: false,
                error: payload
            };
        case LAST_WAKEUPS_GET_SUCCESS:
            return {
                ...state,
                lastWakeups: payload.lastWakeups,
                isGettingLastWakeups: false
            };
        case DEVICES_WAKEUPDAYS_UPDATE_REQUEST:
        case DEVICES_WAKEUPTIMES_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingTimes: true
            };
        case DEVICES_WAKEUPDAYS_UPDATE_SUCCESS:
        case DEVICES_WAKEUPTIMES_UPDATE_SUCCESS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    const found = payload.data.find(updatedDevice => device._id === updatedDevice._id);

                    return found ? {
                        ...device,
                        // wakeupTimes: payload.wakeupTimes,
                        // wakeupDays: payload.wakeupDays,
                        pendingWakeupTimes: found.pendingWakeupTimes,
                        pendingWakeupDays: found.pendingWakeupDays,
                    } : device;
                }),
                isUpdatingTimes: false
            };
        case DEVICES_CLEARALERT_REQUEST:
            return {
                ...state,
                isClearingDeviceAlert: true
            };
        case DEVICES_CLEARALERT_FAILURE:
            return {
                ...state,
                isClearingDeviceAlert: false,
                error: payload
            };
        case DEVICES_CLEARALERT_SUCCESS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    const found = payload.ids.find(updatedDevice => device._id === updatedDevice);

                    return found ? {
                        ...device,
                        alert: null,
                        alertDate: null,
                    } : device;
                }),
                isClearingDeviceAlert: false
            };
        case DEVICES_SET_FAVOURITE_REQUEST:
            return {
                ...state,
                isSettingFavourite: true
            };
        case DEVICES_SET_FAVOURITE_FAILURE:
            return {
                ...state,
                isSettingFavourite: false,
                error: payload
            };
        case DEVICES_SET_FAVOURITE_SUCCESS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    const found = payload.ids.find(updatedDevice => device._id === updatedDevice);

                    return found ? {
                        ...device,
                        favourite: payload.favourite,
                    } : device;
                }),
                isSettingFavourite: false
            };
        case DEVICE_ADD_REQUEST:
            return {
                ...state,
                isUpdatingDevice: true
            };
        case DEVICE_ADD_SUCCESS:
            // console.log(payload);
            return {
                ...state,
                devices: [ payload, ...state.devices],
                isUpdatingDevice: false
            };
        case DEVICE_ADD_FAILURE:
            return {
                ...state,
                isUpdatingDevice: false,
                error: payload
            };
        case DEVICE_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingDevice: true
            };
        case DEVICE_UPDATE_SUCCESS:
            // const updated = {};
            // if(payload.name || payload.name === '') updated.name = payload.name;
            // if(payload.groupId || payload.groupId === '') updated.groupId = payload.groupId;
            // if(payload.tamperState) updated.tamperState = payload.tamperState;
            // if(payload.pendingTamperState) updated.pendingTamperState = payload.pendingTamperState;
            // if(payload.operatingMode) updated.operatingMode = payload.operatingMode;
            // if(payload.pendingOperatingMode) updated.pendingOperatingMode = payload.pendingOperatingMode;
            // console.log(updated);

            // console.log(payload);

            const updatedDevices = payload.data.map(item => {
                const updated = {};

                if(item.name || item.name === '') updated.name = item.name;
                if(item.groupId || item.groupId === '') updated.groupId = item.groupId;
                if(item.tamperState) updated.tamperState = item.tamperState;
                if(item.pendingTamperState) updated.pendingTamperState = item.pendingTamperState;
                if(item.operatingMode) updated.operatingMode = item.operatingMode;
                if(item.pendingOperatingMode) updated.pendingOperatingMode = item.pendingOperatingMode;
                if(item.wakeupCountThreshold) updated.wakeupCountThreshold = item.wakeupCountThreshold;

                return { _id: item._id, updated };
            });

            return {
                ...state,
                devices: state.devices.map((device) => {
                    const found = updatedDevices.find(updatedDevice => device._id === updatedDevice._id);
                    return found ? { ...device, ...found.updated } : device;
                }),
                isUpdatingDevice: false
            };

        case DEVICE_REMOVE_REQUEST:
            return {
                ...state,
                isRemovingDevice: true
            };
        case DEVICE_REMOVE_SUCCESS:
            console.log(payload);
            return {
                ...state,
                devices: state.devices.filter(device => !payload.ids.find(id => id === device._id)),
                isRemovingDevice: false
            };
        case DEVICE_REMOVE_FAILURE:
            return {
                ...state,
                isRemovingDevice: false,
                error: payload
            };

        case DEVICE_GET_RECOVERY_CODE_REQUEST:
            return {
                ...state,
                isGettingRecoveryCode: true
            };
        case DEVICE_GET_RECOVERY_CODE_SUCCESS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    return device._id === payload.device.deviceId ? { ...device, recoveryCode: payload.device.recoveryCode, stolenState: payload.device.stolenState } : device;
                }),
                isGettingRecoveryCode: false
            };
        case DEVICE_GET_RECOVERY_CODE_FAILURE:
            return {
                ...state,
                isGettingRecoveryCode: false
            };
        case DEVICE_CLEAR_RECOVERY_CODE_REQUEST:
            return {
                ...state,
                isGettingRecoveryCode: true
            };
        case DEVICE_CLEAR_RECOVERY_CODE_SUCCESS:
            return {
                ...state,
                devices: state.devices.map((device) => {
                    return device._id === payload.device.deviceId ? { ...device, recoveryCode: null, stolenState: payload.device.stolenState } : device;
                }),
                isGettingRecoveryCode: false
            };
        case DEVICE_CLEAR_RECOVERY_CODE_FAILURE:
            return {
                ...state,
                isGettingRecoveryCode: false
            };
        case DEVICES_GET_INSTALLATION_HISTORY_REQUEST:
            return {
                ...state,
                isGettingInstallationHistory: true
            };
        case DEVICES_GET_INSTALLATION_HISTORY_SUCCESS:
            const history = payload.result.deviceId ? {[payload.result.deviceId]: payload.result.history }  : { };
            const installers = payload.result.installers;
            return {
                ...state,
                history,
                installers,
                isGettingInstallationHistory: false
            };
        case DEVICES_GET_INSTALLATION_HISTORY_FAILURE:
            return {
                ...state,
                isGettingInstallationHistory: false,
                error: payload
            };
        case DEVICES_GET_NOTES_REQUEST:
            return {
                ...state,
                isGettingNotes: true
            };
        case DEVICES_GET_NOTES_SUCCESS:
            const notes = payload.result.deviceId ? {[payload.result.deviceId]: payload.result.notes }  : { };
            const notesUsers = payload.result.users;
            return {
                ...state,
                notes,
                notesUsers,
                isGettingNotes: false
            };
        case DEVICES_GET_NOTES_FAILURE:
            return {
                ...state,
                isGettingNotes: false,
                error: payload
            };
        case DEVICES_SET_NOTE_REQUEST:
            return {
                ...state,
                addingNote: true,
                addNoteError: null
            };
        case DEVICES_SET_NOTE_SUCCESS:
            const error = payload.status === 'error' ? payload.error : null;
            return {
                ...state,
                addingNote: false,
                addNoteError: error
            };
        case DEVICES_SET_NOTE_FAILURE:
            return {
                ...state,
                addingNote: false,
                addNoteError: payload.error
            };
        case PROXIMITY_GET_REQUEST:
            return {
                ...state,
                isGettingProximity: true
            };
        case PROXIMITY_GET_SUCCESS:
            return {
                ...state,
                isGettingProximity: false,
                proximities: payload.results
            }
        case PROXIMITY_GET_FAILURE:
            return {
                ...state,
                isGettingProximity: false
            }
        case UPDATE_DEVICE_TAGS_REQUEST:
            return {
                ...state,
                isUpdatingDeviceTags: true,
            }
        case UPDATE_DEVICE_TAGS_SUCCESS:
            return {
                ...state,
                devices: state.devices.map(device => {
                    const found = payload.ids.find(id => device._id === id);
                    return found ? {...device, tags: payload.tags } : device;
                }),
                isUpdatingDeviceTags: false,
            }
        case UPDATE_DEVICE_TAGS_FAILURE:
            return {
                ...state,
                isUpdatingDeviceTags: false,
                error: payload
            }
        case COUNT_FILTER_REQUEST:
            return {
                ...state,
                error: null
            }
        case COUNT_FILTER_SUCCESS:
            return {
                ...state,
                filterCounts: payload.counts || {},
                error: null
            }
        case COUNT_FILTER_FAILURE:
            return {
                ...state,
                error: payload
            }
        case LOGOUT:
            return {
                ...initialState,
            };
        case CLEAR_DEVICE_ERRORS:
        default: return { ...state, error: null };
    }
};
