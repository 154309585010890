import './locations.css';

import React, {useState, useLayoutEffect, useEffect} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Divider,
    Header,
    Grid,
    Segment,
    Embed,
    Dropdown,
    Label,
    Icon,
    List,
    Button,
    Checkbox,
    Dimmer,
    Loader,
    Menu,
    Table,
    Responsive,
    Confirm,
    Input, Pagination
} from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";

import Map from '../map';
// import Countdown from '../countdown';

import {authenticate} from "../../actions/auth";
import {userDetailsGet} from "../../actions/user";
import {groupsGet, groupCreate, groupUpdate, setPersistedGroups} from "../../actions/groups";
import {
    locationsGet,
    devicesGet,
    addressGet,
    deviceRecovery,
    deviceClearRecovery,
    lastWakeupsGet,
    lastLocationsGet, clearDeviceErrors, proximityGet
} from '../../actions/devices';
import {
    geofencesGet,
    geofencesSave,
    addGeofenceRequest,
    updateGeofenceRequest,
    geofencesDevicesGet,
    updateGeofenceDeviceRequest,
    saveGeofenceDevices,
    removeUnsavedGeofences,
    geofencesRemove, geofencesAssignedDevicesGet, geofencesUnassignedDevicesGet
} from '../../actions/geofences';
import {AnimatePresence, motion} from "framer-motion";
import ProtectedComponent from "../../utils/protected-component";
import {hasAccess} from "../../utils/protected-component/protected-component";
import {Redirect, useParams} from "react-router-dom";
import BaseModal from "../base-modal";
import RemoveDevice from "../remove-device";
import ReportStolen from "../report-stolen";
import {resetActioned} from "../../actions/ui";
import Config from "../../config";

const arcEnabled = Config.arc;

const CodeDigit = styled(Container)`
  &&& {
    display: inline-block;
    margin: 0;
    text-align: center;
    font-size: 24px;
    outline: none;
    width: 16%;

  }
`;

const HeaderCellText = styled.span`
  &&& {
      font-size: 1.1em;
  }`

const CodeTable = styled(Table)`
  font-size: 24px;
  border: 0 !important;
`;

const Locations = (props) => {
    const { id } = useParams();
    const [selected, setSelected] = useState(null);
    let [searchValue, setSearchValue] = useState(id || '');
    let [initialQuerySelect, setInitialQuerySelect] = useState(0);

    useLayoutEffect(() => {
        const initialQuery = {skip: 0, limit: limit};
        if(searchValue) {
            initialQuery.search = searchValue;
            setInitialQuerySelect(1);
        }

        if(props.groups.persistedGroups && props.groups.persistedGroups.length) {
            initialQuery.groupsQuery = props.groups.persistedGroups;
        }

        props.lastLocationsGet(initialQuery);
        props.groupsGet(false);
        props.geofencesGet();
    }, []);

    const limit = 25;
    const [ creationStep, setCreationStep ] = useState(0);
    const [ geofenceChanges, setGeofenceChanges ] = useState(false);
    const [ newIndex, setNewIndex ] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({skip: 0, limit: limit});

    const [go, setGo] = useState(false);

    const [width, setWidth] = useState(null);
    const [centered, setCentered] = useState(true);
    const [previousLocationIndex, setPreviousLocationIndex] = useState(0);
    const [checked, setChecked] = useState([]);
    const [mapType, setMapType] = useState('locations');
    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [query, setQuery] = useState({
        startDate: new Date(moment(new Date()).subtract(2, 'd').format('YYYY-MM-DD')), // new Date(), // new Date(moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')),
        endDate: new Date(),
        alertFilter: '',
        groups: []
    });
    const [proximity, setProximity] = useState({
        startDate: new Date(moment(new Date()).subtract(2, 'd').format('YYYY-MM-DD')),
        endDate: new Date(),
        distance: 0.1,
    })
    const [liveTrack, setLiveTrack] = useState(null);

    const [reportStolen, setReportStolen] = useState(null) ;

    // Component will mount
    useEffect(() => {
        // console.log('USE EFFECT: ', liveTrack, query.alertFilter);
        if (query.alertFilter !== 'tracking') {
            clearInterval(liveTrack);
            setLiveTrack(null);
        }

        if(getChecked(searchValue) && initialQuerySelect === 1 && props.devices.lastLocationsView.length > 0) {
            setChecked([...checked, getChecked(searchValue)]);
            setSelected(searchValue);
            setInitialQuerySelect(2);
            setPreviousLocationIndex(0);
            setCentered(true);
            setGo(true);
        }
    });

    // Component will unmount
    useEffect(() => {
        return () => {
            // console.log('CLEANING UP!', liveTrack);
            if (liveTrack) clearInterval(liveTrack);
        };

    }, [liveTrack]);

    const handleOnUpdate = (e, {width}) => setWidth(width);
    const menuSize = width <= Responsive.onlyTablet.minWidth ? 'tiny' : 'small';
    const mobileSized = (width < Responsive.onlyTablet.minWidth);
    const tabletSized = (width <= Responsive.onlyTablet.maxWidth && width >= Responsive.onlyTablet.minWidth);
    const desktopSized = (width > Responsive.onlyTablet.maxWidth);

    const getRecoveryCode = (deviceId) => {
        props.deviceRecovery({deviceId});
    };

    const onCloseModal = () => {
        props.clearDeviceErrors();
        props.resetActioned();
        setReportStolen(null);
    };

    const clearRecoveryCode = (deviceId) => {
        props.deviceClearRecovery({deviceId});
    };

    const search = (e) => {
        setSearchValue(e.target.value);
    };

    const clearSearch = () => {
        setSearchValue('');
        props.lastLocationsGet({groupsQuery: props.groups.persistedGroups });
    };

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            submitSearch();
        }
    };

    const submitSearch = () => {
        props.lastLocationsGet({ search: searchValue, groupsQuery: props.groups.persistedGroups });
    };

    const getDevice = (id, view) => {
        if (props.devices.devices.length === 0 || view.length === 0) return null;

        const deviceId = id ? id : view[0]._id;

        return props.devices.devices.find(item => item._id === deviceId);
    };

    const checkDevice = (e) => {
        let id;

        if(e.stopPropagation) {
            e.stopPropagation();
            id = e.target.id;
        } else {
            id = e;
        }

        if (mapType !== 'geofences' && mapType !== 'previouslocations') {
            const index = checked.length > 0 ? checked.findIndex(x => x.id === id) : -1;
            if (index === -1) {
                // setChecked([...checked, id]);
                setChecked([...checked, getChecked(id)])
            } else {
                setChecked(checked.filter(check => check.id !== id));
            }
        }

        if (mapType === 'previouslocations') {
            const index = checked.length > 0 ? checked.findIndex(x => x.id === id) : -1;
            if (index === -1) {
                setChecked([getChecked(id)]);
            }
        }
    };

    const isChecked = (id) => {
        if (mapType !== 'geofences') {
            const index = checked?.length > 0 ? checked?.findIndex(x => x?.id === id) : -1;
            return index !== -1
        }

        return false;
    };

    const getStatusColor = (device, idx = 0) => {
        // console.log(device);
        if (device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'gnss') return 'green';
        if (device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'lbs' && (device.wakeups[idx].latitude !== "" || device.wakeups[idx].signalStrength.substring(0, 3) !== '99,')) return 'yellow';
        return 'red';
    };

    const getBatteryStatus = (battery) => {
        if (!battery) return;
        let batteryObj;

        if (battery >= 75 && battery <= 100) batteryObj = {icon: 'battery full', color: 'green'};
        if (battery > 50 && battery < 75) batteryObj = {icon: 'battery three quarters', color: 'green'};
        if (battery > 25 && battery <= 50) batteryObj = {icon: 'battery half', color: 'yellow', fontColor: 'orange'};
        if (battery >= 10 && battery <= 25) batteryObj = {icon: 'battery low', color: 'yellow', fontColor: 'orange'};
        if (battery >= 0 && battery < 10) batteryObj = {icon: 'battery empty', color: 'red'};

        return <span style={{color: batteryObj.fontColor || batteryObj.color, fontSize: '10px'}}><Icon
            name={batteryObj.icon} color={batteryObj.color}/>{battery}%</span>
    };

    const SecurityCode = (props) => (
        <Container style={{paddingTop: '10px'}}>
            {props.currentDevice && !props.currentDevice.recoveryCode ?
                <Button
                    fluid
                    basic
                    onClick={() => props.getCode(props.deviceId)}
                    loading={props.isGettingRecoveryCode && selected === props.deviceId}
                >Get Security Pin</Button> :
                <Button.Group fluid basic vertical={props.vertical}>
                    <Button color='red'
                            style={{border: 'solid 2px red'}}>PIN {props.currentDevice && props.currentDevice.recoveryCode}</Button>
                    <Button onClick={() => props.clearCode(props.deviceId)}
                            loading={props.isGettingRecoveryCode && selected === props.deviceId}
                    >Cancel</Button>
                </Button.Group>}
        </Container>
    );

    const getBalance = (deviceId) => {
        if(!props.devices.balances[deviceId]) return `${props.devices.startingBalance} wake-ups remaining` || null;
        return props.devices.startingBalance ? `${props.devices.startingBalance - props.devices.balances[deviceId]} wake-ups remaining` : `${props.devices.balances[deviceId]} used`
    };

    const deviceFound = (deviceId) => {
        props.deviceClearRecovery({deviceId});
    }
    const deviceClear = (deviceId) => {
        props.deviceClearRecovery({deviceId, cancel: true});
    }

    const [unCheckedProximityDevices, setUnCheckedProximityDevices] = useState([]);

    const handleToggleProximityDevice = (deviceId) => {
        setUnCheckedProximityDevices((prev) => {
            if (prev.includes(deviceId)) {
                return prev.filter((id) => id !== deviceId);
            } else {
                return [...prev, deviceId];
            }
        });
    };

    const uncheckedProximity = (deviceId) => unCheckedProximityDevices.includes(deviceId);

    const proximityDevices = Object.values(
        props.devices.proximities.reduce((acc, item) => {
            acc[item.deviceId] = item;
            return acc;
        }, {})
    ).map((device, idx) => (
        <List.Item key={device.deviceId+'-'+idx}
                   onClick={() => handleToggleProximityDevice(device.deviceId)}
        >
            <Icon inverted>
                <Checkbox
                    id={device.deviceId}
                    checked={!uncheckedProximity(device.deviceId)}
                />
            </Icon>
            <List.Content>
                <List.Header as='h4'>
                    {device.deviceId}
                </List.Header>
            </List.Content>
        </List.Item>
    ));

    const optionsAll = props.devices.lastLocationsView.map((device, i) => {
        const currentDevice = getDevice(device._id, props.devices.lastLocationsView);
        return (
            <List.Item key={device._id}
                       // style={{border: currentDevice && currentDevice.operatingMode === 'Tracking' ? 'solid 1px red' : null}}
                       onClick={() => {
                           setPreviousLocationIndex(0);
                           setSelectedId(device._id);
                           setCentered(true);
                       }}
                       active={selected ? selected === device._id : props.devices.lastLocationsView[0]._id === device._id}
            >
                {(props.user.username === 'thatcham@intelizzz.co.uk') || (arcEnabled) ? <List.Content floated='right'>
                    <div>
                        {!currentDevice.stolenState ? (
                            <Dropdown icon={<Icon name="ellipsis horizontal" />} direction="left">
                                <Dropdown.Menu>
                                    <Dropdown.Item text="Report Stolen" onClick={() => setReportStolen(device._id)} />
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : currentDevice.stolenState === 1 ? (
                            <Dropdown
                                style={{ backgroundColor: '#db2828', color: 'white', fontSize: '.85714286rem', fontWeight: 'bold', padding: 6, minWidth: '104.109px', textAlign: 'center' }}
                                text={props.devices.isGettingRecoveryCode ? <Icon name="spinner" loading /> : 'Reported Stolen'}
                                direction='left'
                                floating
                                button
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item text='Found' onClick={() => deviceFound(device._id)} />
                                    <Dropdown.Item text='Cancel' onClick={() => deviceClear(device._id)} />
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : currentDevice.stolenState === 2 ? (
                            <Dropdown
                                style={{ backgroundColor: 'rgb(33,186,69)', color: 'white', fontSize: '.85714286rem', fontWeight: 'bold', padding: 6, minWidth: '104.109px', textAlign: 'center' }}
                                text={props.devices.isGettingRecoveryCode ? <Icon name="spinner" loading /> : 'Found'}
                                direction='center'
                                floating
                                button
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item text='Clear' onClick={() => deviceClear(device._id)} />
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : null}
                    </div>
                </List.Content> : null}
                {mapType !== 'previouslocations' ? <Icon inverted>
                    <Checkbox
                        id={device._id}
                        onChange={checkDevice}
                        checked={isChecked(device._id)}
                        disabled={(mapType === 'geofences' && selectedGeofence === null)}
                    />
                </Icon> : null}
                <List.Content
                    onClick={(e) => {
                        if(mapType === 'previouslocations') {
                            checkDevice(device._id);
                        }
                        setPreviousLocationIndex(0);
                        setCentered(true);
                        setGo(true);
                    }}
                >
                    <List.Header as='h4'>
                        <Label empty circular color={getStatusColor(device)}/> {device.name || device._id}
                    </List.Header>
                    <List.Content><span style={{fontSize: '0.9em', padding: 2, color: '#666'}}>{getBalance(device._id)}</span></List.Content>
                    <List.Content>
                        {/*getBatteryStatus(device.data.battery)*/} {moment(device.wakeups[0].date).format('LLLL')}
                    </List.Content>



                </List.Content>
            </List.Item>
        );
    });

    const optionsDated = props.devices.locationsView.map((device, i) => {
        const currentDevice = getDevice(device._id, props.devices.lastLocationsView);
        return (
            <List.Item key={device._id}
                       onClick={() => {
                           setSelectedId(device._id);
                           setPreviousLocationIndex(0);
                           setCentered(true);
                       }}
                       active={selected ? selected === device._id : props.devices.locationsView[0]._id === device._id}
            >
                <Icon inverted>
                    <Checkbox
                        id={device._id}
                        onChange={checkDevice}
                        checked={isChecked(device._id)}
                        disabled={(mapType === 'geofences' && selectedGeofence === null)}
                    />
                </Icon>
                <List.Content
                    onClick={() => {
                        setCentered(true);
                    }}
                >
                    <List.Header as='h4'>
                        <Label empty circular color={getStatusColor(device)}/> {device.name || device._id}
                    </List.Header>
                    <List.Content><span style={{fontSize: '0.9em', padding: 2, color: '#666'}}>{getBalance(device._id)}</span></List.Content>
                    <List.Content>
                        {/*getBatteryStatus(device.data.battery)*/}
                        {device && device.wakeups && device.wakeups[0] ? moment(device.wakeups[0].date).format('LLLL') : null}
                    </List.Content>
                </List.Content>
            </List.Item>
        );
    });

    const DatePickerInput = ({value, onClick}) => (
        <Button basic icon labelPosition='right' onClick={onClick}>
            <Icon name='calendar alternate outline'/>{moment(value).local().format('DD/MM/YYYY')}
        </Button>
    );

    const setSelectedId = (id) => {
        // console.log('**********: ', query);
        const payload = {
            timezone: moment.tz.guess(),
            ...query
        };

        setSelected(id);
        if(id) {
            payload.deviceId = id;
        }

        if(props.groups.persistedGroups && props.groups.persistedGroups.length) {
            payload.groups = props.groups.persistedGroups;
        }

        if(mapType === 'previouslocations' && id) {
            props.locationsGet(payload);
        }
    }

    const change = (data, resetPage = false) => {
        const updateQuery = {...query, ...data};
        const payload = {
            timezone: moment.tz.guess()
        };
        const lastLocPayload = {};

        setQuery(updateQuery);
        props.groupsSetPersisted((data && data.groups) || []);

        if (updateQuery.startDate) payload.startDate = moment(updateQuery.startDate).utc().format('YYYY-MM-DD');
        if (searchValue) lastLocPayload.search = searchValue;
        if (updateQuery.endDate) payload.endDate = moment(updateQuery.endDate).add(1, 'd').utc().format('YYYY-MM-DD');
        if (updateQuery.alertFilter) payload[updateQuery.alertFilter] = true;
        if (updateQuery.groups && updateQuery.groups.length > 0) {
            payload.groupsQuery = updateQuery.groups;
            lastLocPayload.groupsQuery = updateQuery.groups;
        }

        props.lastLocationsGet({...lastLocPayload, ...pagination, skip: resetPage ? 0 : pagination.skip, groupsQuery: updateQuery.groups});
        if(selected) {
            props.locationsGet({...payload, ...query, ...data, deviceId: selected});
        }
        // props.locationsGet(payload);

        if (updateQuery.alertFilter === 'tracking') {
            const liveTracker = setInterval(() => {
                props.locationsGet(payload);
            }, 3000);

            setLiveTrack(liveTracker);
        }
    };

    const geofenceTypeToIcon = (type) => {
        const types = {
            circle: 'circle outline',
            rectangle: 'square outline',
            polygon: 'pencil alternate'
        };

        return types[type];
    };

    const isChanges = () => {
        if(selectedGeofence === null) return;
        const current = props.geofences.geofences[selectedGeofence];
        const original = props.geofences.original.find((origGeofence) => origGeofence._id === current._id);

        if(!original) return;

        // console.log(current, original);

        return !((JSON.stringify(original.detail) === JSON.stringify(current.detail))
            && original.name === current.name
            && original.inside === current.inside
        );
    };

    const revertGeofenceChanges = () => {
        if(selectedGeofence === null) return;
        const current = props.geofences.geofences[selectedGeofence];
        const original = props.geofences.original.find((origGeofence) => origGeofence._id === current._id);

        if(!original) return;

        if(!(JSON.stringify(original.detail) === JSON.stringify(current.detail))) {
            props.updateGeofence({
                index: selectedGeofence,
                value: original,
                revert: true
            })
        }

        if(!(original.name === current.name)) {
            props.updateGeofence({
                index: selectedGeofence,
                property: 'name',
                value: original.name,
                meta: true
            })
        }

        if(!(original.inside === current.inside)) {
            props.updateGeofence({
                index: selectedGeofence,
                property: 'inside',
                value: original.inside,
                meta: true
            })
        }

    };

    const setLocationIndex = (index) => {
        setPreviousLocationIndex(index);
        setCentered(true);
    }

    const setNewSelected = (index) => {
        const id = props.geofences.geofences[index]._id;
        setSelectedGeofence(index);
        if (id) props.geofencesDevicesGet(id);
        if(id) props.getAssignedDevices(id);
        if(id) props.getUnassignedDevices({geofenceId: id});
    };

    function onSelected(e) {
        // console.log('selected', e);
        // Do nothing if we're in creation mode
        if(creationStep > 0) return;
        const index = e.index !== undefined ? e.index : this && this.index;
        if(geofenceEditMode() && isChanges() && selectedGeofence !== null) {
            setNewIndex(index);
            setGeofenceChanges(true);
            return;
        }
        setNewSelected(index);
    };

    function clearSelectedGeofence(e) {
        if(geofenceEditMode() && isChanges()) {
            setGeofenceChanges(true);
        } else {
            setSelectedGeofence(null);
        }
    };

    const saveShapes = () => {
        const id = props.geofences.geofences[selectedGeofence]._id;

        // const geofences = props.geofences.geofences.map(({ devices, ...rest }) => rest);
        const newGeofences = props.geofences.geofences.filter(geofence => !('_id' in geofence));
        const changedGeofences = props.geofences.geofences.filter(geofence => geofence.changed === true).map(({changed, ...rest}) => rest);

        if (newGeofences.length > 0 || changedGeofences.length > 0) {
            props.saveGeofences({
                newGeofences,
                changedGeofences,
                selectedGeofenceId: id
            });
        }
    };

    const geofenceOptions = props.geofences.geofences.map((geofence, i) => {
        // const currentDevice = getDevice(device._id, props.devices.lastLocationsView);
        return (
            <List.Item key={geofence._id}
                       onClick={() => {
                           // Don't allow clicks if we're creating a new geofence
                           if(creationStep > 0) return;
                           onSelected({ index: i });
                           if (geofence._id) props.geofencesDevicesGet(geofence._id);
                           setCentered(true);
                       }}
                       active={selectedGeofence !== null ? selectedGeofence === i : null}
            >
                <List.Content>
                    <List.Header as='h4'>
                        <Icon name={geofenceTypeToIcon(geofence.type)} />{geofence.name || 'Untitled Geofence'}
                    </List.Header>
                </List.Content>
            </List.Item>
        );
    });

    const toggleMap = (type) => {
        change({alertFilter: type});
        if (type === 'previouslocations') {
            setChecked(checked.length > 0 ? [checked[0]] : []);
        }
        setMapType(type);
        setSelectedGeofence(null);
        setGo(false);
    };

    const WakeItem = props => (
        <List.Item key={props.id}>
            <List.Content>
                <List.Header as='h4'>
                    <Label empty circular
                           color={getStatusColor(props.device, props.wakeupIdx)}/> {props.device.name || props.device._id}
                </List.Header>
                <List.Content style={{color: 'grey', fontSize: '12px'}}>
                    {moment(props.device.wakeups[props.wakeupIdx].date).format('LLLL')}
                    <List.Content style={{color: 'black', fontSize: '12px'}}>
                        {props.device.wakeups[props.wakeupIdx].address ? props.device.wakeups[props.wakeupIdx].address : 'Address not found.'}
                    </List.Content>
                </List.Content>
            </List.Content>
        </List.Item>
    );

    const changeProximity = (date) => {
        const newProximityData = {...proximity, ...date};
        setProximity(newProximityData);

        if(newProximityData.latitude && newProximityData.longitude) {
            getProximity(newProximityData);
        }
    }

    const createWakeupLocation = (locationIdx, wakeupIdx, view, addIndex = false) => {
        const id = view[locationIdx]._id;
        const locationMethod = view[locationIdx].wakeups[wakeupIdx].locationMethod;
        const lat = view[locationIdx].wakeups[wakeupIdx][locationMethod].latitude;
        const lng = view[locationIdx].wakeups[wakeupIdx][locationMethod].longitude;
        const address = view[locationIdx].wakeups[wakeupIdx].address;
        const date = view[locationIdx].wakeups[wakeupIdx].date;
        const speed = locationMethod === 'gnss' ? view[locationIdx].wakeups[wakeupIdx][locationMethod].speed : null;
        const direction = locationMethod === 'gnss' ? view[locationIdx].wakeups[wakeupIdx][locationMethod].direction : null;
        const hdop = locationMethod === 'gnss' ? view[locationIdx].wakeups[wakeupIdx][locationMethod].GNSSStatus.split(',')[3] : null;
        const text = addIndex ? wakeupIdx.toString() : null;
        const contentInfo = <WakeItem device={view[locationIdx]} wakeupIdx={wakeupIdx}/>;
        const content = <div>{contentInfo}</div>;
        // const operatingMode = view[locationIdx].data.operatingMode;
        const operatingMode = view[locationIdx].wakeups[wakeupIdx].operatingMode;
        return {
            id, lat, lng, text, content, hdop, locationMethod, address, date, speed, direction, operatingMode
        };
    };

    const getChecked = check => {
        const idx = props.devices.lastLocationsView.findIndex(loc => loc._id === check);
        if (idx === -1) return null;
        return createWakeupLocation(idx, 0, props.devices.lastLocationsView);
    };

    const getPreviousChecked = check => {
        const idx = props.devices.locationsView.findIndex(loc => loc._id === check.id);
        if (idx === -1) return null;

        const previousLocations = [];
        const wakeupsLength = props.devices.locationsView[idx].wakeups.length;

        for (let i = 0; i < wakeupsLength; i++) {
            previousLocations.push(createWakeupLocation(idx, i, props.devices.locationsView, true));
        }

        return previousLocations;
    };

    const currentMarkerEnabled = (mapType) => {
        const allowedMarkers = [
            'locations', 'tamper', 'tracking'
        ];

        return allowedMarkers.find(type => type === mapType);
    };

    const getMarkers = () => {
        let markers;
        if (currentMarkerEnabled(mapType)) markers = checked; //checked.map(getChecked).filter(item => item !== null);

        return markers && markers.length > 0 ? markers : [];
    };

    const getPreviousMarkers = () => {
        let markers;

        if (mapType === 'previouslocations') markers = checked.map(getPreviousChecked).filter(item => item !== null);

        const markersWithIndex = markers && markers.length > 0 ? [].concat(...markers).map((item, idx) => {
            return {...item, text: '' + (idx + 1)}
        }) : [];

        return markersWithIndex;
    };

    const getDeviceLocationIndex = (deviceId, locations) => {
        const idx = locations.findIndex(item => item._id === deviceId);

        return idx !== -1 ? idx : 0;
    };

    const getModeColor = (mode) => {
        const colors = {
            tracking: 'teal',
            geofences: 'pink',
            previouslocations: 'violet',
            tamper: 'red',
            locations: 'green',
            proximity: 'blue'
        };

        return colors[mode];
    };

    const checkAll = (e) => {
        const currentChecked = props.devices.devices.filter(device => checked.find(check => check.id === device._id));
        if (currentChecked.length === props.devices.devices.length) {
            setChecked([]);
        } else {
            const unchecked = props.devices.devices.filter(device => !checked.find(check => check.id === device._id))
                                                    .map(device => getChecked(device._id));
            setChecked([...checked, ...unchecked]);
        }
    };

    const getProximity = (data) => {
        const payload = { ...data};
        props.proximityGet(payload);
    }

    const allCheckedCurrent = () => {
        const currentChecked = props.devices.devices.filter(device => checked.find(check => check?.id === device?._id));

        return currentChecked.length === props.devices.devices.length;
    }

    const options = (mapType === 'previouslocations' || mapType === 'locations' || mapType === 'geofences') ? optionsAll : optionsDated;
    const geofenceEditMode = () => mapType === 'geofences' && creationStep === 0 && selectedGeofence !== null && props.geofences.geofences.length > 0;

    const checkAllProximity = () => {
        if (unCheckedProximityDevices.length === 0) {
            // Add all device ids if none are unchecked
            setUnCheckedProximityDevices(props.devices.proximities.map(device => device.deviceId));
        } else {
            // Clear the array to select all
            setUnCheckedProximityDevices([]);
        }
    };

    return (
        <Responsive
            as={Container}
            fireOnMount
            onUpdate={handleOnUpdate}
            fluid
        >
            <Container>
                <Segment color={getModeColor(mapType)} style={{padding: '0', margin: 10}}>
                    <Grid columns={1} celled='internally' stackable>
                        <Grid.Column width={4} >
                            {mapType === 'proximity' ? <Container textAlign='center' style={{height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <Button.Group>
                                        <DatePicker
                                            selected={proximity.startDate}
                                            onChange={(date) => changeProximity({...proximity, startDate: date})}
                                            customInput={
                                                <DatePickerInput/>
                                            }
                                        />
                                        <Button.Or text='to'/>
                                        <DatePicker
                                            selected={proximity.endDate}
                                            onChange={(date) => changeProximity({...proximity, endDate: date})}
                                            maxDate={new Date(proximity.startDate).setMonth(proximity.startDate.getMonth() + 1)}
                                            customInput={
                                                <DatePickerInput/>
                                            }
                                        />
                                    </Button.Group>{' '}
                                </Container> : null}
                            {mapType !== 'geofences' && mapType !== 'proximity' ? <Dropdown
                                fluid
                                placeholder='Group'
                                selection
                                clearable
                                multiple
                                value={props.groups.persistedGroups}
                                onChange={(e, {value}) => {
                                    setCurrentPage(1);
                                    setPagination({skip: 0, limit: pagination.limit})
                                    change({groups: value}, true);
                                }}
                                options={props.groups.groups.map(group => ({
                                    key: group._id,
                                    value: group._id,
                                    text: group.name
                                }))}
                            /> : null}
                            {mapType !== 'geofences' && mapType !== 'proximity' ? <Input
                                fluid
                                type='text'
                                icon='search'
                                placeholder='Search devices...'
                                icon={searchValue ? { name: 'cancel', link: true, onClick: clearSearch } : null}
                                style={{
                                    textOverflow: 'ellipses',
                                    marginTop: '5px',
                                }}
                                value={searchValue}
                                onChange={search}
                                onKeyDown={onKeyDown}
                            /> : null}
                            {/*<Button color='teal' content='Search' onClick={submitSearch} />*/}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Menu icon='labeled' size={menuSize} pointing>
                                <Menu.Item
                                    name='locations'
                                    active={mapType === 'locations'}
                                    color={mapType === 'locations' ? getModeColor(mapType) : null}
                                    onClick={() => toggleMap('locations')}
                                >
                                    <Icon name='location arrow'/>
                                    Current<br/>Location
                                </Menu.Item>
                                <ProtectedComponent
                                    permissions={['locations.previous_locations.can_view']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Menu.Item
                                        name=''
                                        active={mapType === 'previouslocations'}
                                        color={mapType === 'previouslocations' ? getModeColor(mapType) : null}
                                        onClick={() => toggleMap('previouslocations')}
                                    >
                                        <Icon name='history'/>
                                        Previous<br/>Locations
                                    </Menu.Item>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    permissions={['locations.locate_mode.can_view']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Menu.Item
                                        name='tracking'
                                        active={mapType === 'tracking'}
                                        color={mapType === 'tracking' ? getModeColor(mapType) : null}
                                        onClick={() => toggleMap('tracking')}
                                    >
                                        <Icon name='search'/>
                                        Locate Mode
                                    </Menu.Item>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    permissions={['locations.tamper_alerts.can_view']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Menu.Item
                                        name='tamper'
                                        active={mapType === 'tamper'}
                                        color={mapType === 'tamper' ? getModeColor(mapType) : null}
                                        onClick={() => toggleMap('tamper')}
                                    >
                                        <Icon name='alarm'/>
                                        Tamper
                                    </Menu.Item>
                                </ProtectedComponent>
                                <ProtectedComponent
                                    permissions={['locations.geofences.can_view']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Menu.Item
                                        name='geofences'
                                        active={mapType === 'geofences'}
                                        color={mapType === 'geofences' ? getModeColor(mapType) : null}
                                        onClick={() => toggleMap('geofences')}
                                    >
                                        <Icon name='globe'/>
                                        Geofences
                                    </Menu.Item>
                                </ProtectedComponent>
                                <Menu.Item
                                    name='proximity'
                                    active={mapType === 'proximity'}
                                    color={mapType === 'proximity' ? getModeColor(mapType) : null}
                                    onClick={() => toggleMap('proximity')}
                                >
                                    <Icon name='map marker alternate'/>
                                    Proximity<br/>Finder<br/><span
                                    style={{fontSize: '0.8em', color: 'lightpink'}}>Beta</span>
                                </Menu.Item>
                            </Menu>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Segment attached secondary clearing size="small">
                                <Checkbox
                                    id={'check-all'}
                                    onChange={mapType !== 'proximity' ? checkAll : checkAllProximity}
                                    checked={mapType !== 'proximity' ? allCheckedCurrent() : unCheckedProximityDevices.length < 1}
                                    label="Select ALL"
                                    fitted
                                />
                                <span style={{float: 'right'}}>{checked.length > 0 ? `${checked.length} selected` : null}</span>
                            </Segment>
                            {mapType !== 'proximity' ? <Container style={{
                                border: 'none',
                                boxShadow: 'none',
                                maxHeight: mobileSized ? '120px' : '600px',
                                minHeight: mobileSized ? '120px' : '600px',
                                overflowY: mobileSized ? 'auto' : 'auto'
                            }}>
                            {(options.length === 0 && props.devices.locationsView > 0) || props.devices.isLoadingDevices ?
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer> :
                                <div>
                                    {options.length === 0 && props.devices.locationsView === 0 && !props.devices.isLoadingDevices ?
                                        <Header as='h4' textAlign='center'>No Results</Header> :
                                        <>
                                            {mapType !== 'geofences' ? <List selection>
                                                {options}
                                            </List> : <List selection relaxed>
                                                {geofenceOptions}
                                            </List>}
                                        </>}
                                </div>}
                            </Container> : null}
                            {mapType === 'proximity' ? <Container style={{
                                border: 'none',
                                boxShadow: 'none',
                                maxHeight: mobileSized ? '120px' : '600px',
                                minHeight: mobileSized ? '120px' : '600px',
                                overflowY: mobileSized ? 'auto' : 'auto'
                            }}>
                                {props.devices.isGettingProximity ? <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer> : <div>{proximityDevices.length > 0 ? <List selection relaxed>
                                        {proximityDevices}
                                </List> : null}</div>}
                            </Container> : null}
                            <Divider fitted />

                            {mapType === 'locations' || mapType === 'previouslocations' ? <Container style={{marginTop: 10}} textAlign="center">

                            <Pagination
                                boundaryRange={1}
                                activePage={currentPage}
                                // defaultActivePage={1}
                                ellipsisItem={null}
                                firstItem={undefined}
                                lastItem={undefined}
                                prevItem={undefined}
                                nextItem={undefined}
                                siblingRange={1}
                                totalPages={Math.ceil(props.devices.documentCount / limit)}
                                onPageChange={(e, {activePage}) => {
                                    const updateQuery = {...query};
                                    setCurrentPage(activePage);
                                    // submitSearch(sortByValue, {
                                    //     tagFilter: statusFilters,
                                    //     searchFilter: searchFilters
                                    // }, {skip: ((activePage - 1) * limit), limit: limit});
                                    const pagQuery = {skip: ((activePage - 1) * limit), limit: limit};

                                    setPagination(pagQuery);

                                    if(searchValue) {
                                        pagQuery.search = searchValue;
                                    }
                                    if (props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
                                        pagQuery.groupsQuery = props.groups.persistedGroups;
                                    }
                                    props.lastLocationsGet(pagQuery);
                                }}
                            />
                                <p>
                        {pagination.skip + 1}-{pagination.skip + pagination.limit < props.devices.documentCount ?
                                    pagination.skip + pagination.limit
                                    : props.devices.documentCount} of {props.devices.documentCount || 0}
                    </p>
                            </Container> : null}
                        </Grid.Column>

                        <Grid.Column id='map-container' width={12}>
                            <Embed active style={{padding: 2}}>
                                <MapContainer
                                    addGeofence={props.addGeofence}
                                    updateGeofence={props.updateGeofence}
                                    locations={props.devices.lastLocationsView.length > 0 && props.devices.lastLocationsView[getDeviceLocationIndex(selected, props.devices.lastLocationsView)] ? props.devices.lastLocationsView[getDeviceLocationIndex(selected, props.devices.lastLocationsView)].wakeups[0] : {}}
                                    geofences={props.geofences}
                                    proximities={props.devices.proximities.filter(device => !uncheckedProximity(device.deviceId))}
                                    centered={centered}
                                    setCentered={setCentered}
                                    selectedDevices={/*selectedGeofence !== null && props.geofences.geofences[selectedGeofence].devices ? props.geofences.geofences[selectedGeofence].devices : */[]}
                                    selectedGeofence={selectedGeofence}
                                    setSelectedGeofence={setSelectedGeofence}
                                    saveGeofences={props.saveGeofences}
                                    saveGeofenceDevices={props.saveGeofenceDevices}
                                    getGeofenceDevices={props.geofencesDevicesGet}
                                    removeGeofence={props.removeGeofence}
                                    deleteGeofence={props.deleteGeofence}
                                    mapType={mapType}
                                    markers={getMarkers()}
                                    previousLocations={mapType === 'previouslocations' ? getPreviousMarkers() : []}
                                    previousLocationIndex={previousLocationIndex}
                                    isTracking={query.alertFilter === 'tracking'}
                                    proximityData={proximity}
                                    setProximityData={setProximity}
                                    creationStep={creationStep}
                                    setCreationStep={setCreationStep}
                                    geofenceEditMode={geofenceEditMode()}
                                    onSelected={onSelected}
                                    clearSelectedGeofence={clearSelectedGeofence}
                                    saveShapes={saveShapes}
                                    getProximity={getProximity}
                                    format={{ mobileSized, tabletSized, desktopSized }}
                                />
                                {(mapType === 'locations' || mapType === 'previouslocations') ? <div style={{
                                    width: '100%',
                                    minHeight: '400px',
                                    // background: 'white',
                                    position: 'absolute',
                                    bottom: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    // border: 'dashed orange 4px',
                                    pointerEvents: 'none',
                                    opacity: 0.8,
                                }}>
                                    <AnimatePresence>
                                        {go && (mapType === 'locations' || mapType === 'previouslocations') ? <motion.div
                                            initial={{height: 0, top: 600}}
                                            animate={{height: 300, top: 0}}
                                            exit={{height: 0, top: 600}}
                                            style={{
                                                padding: 0,
                                                position: 'absolute',
                                                width: mapType === 'locations'  ? '65%' : '100%',
                                                background: 'transparent',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                pointerEvents: 'auto',

                                            }}>
                                            <div style={{margin: 20, padding: 10, borderRadius: 15, background: '#f2f1f1', height: 300, border: '5px solid #3c3c3b'}}>
                                                {/*<div style={{textAlign: 'right', fontWeight: 'bold'}}><Button basic circular style={{boxShadow: 'unset'}} onClick={() => setGo(false)}>X</Button></div>*/}
                                                <div onClick={() => setGo(false)} style={{position: 'absolute', background: 'transparent', right: 8, top: 7, color: 'white', cursor: 'pointer'}}>
                                                    <Icon.Group size='large'>
                                                        <Icon size='large' name='circle' style={{color: 'white', margin: 0}}/>
                                                        <Icon size='large' color='red' name='times circle' link />
                                                    </Icon.Group>
                                                </div>
                                                <div style={{overflowX: 'hidden', overflowY: 'scroll', background: '#f2f1f1', height: '100%', fontFamily: 'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'}}>
                                                    <Divider hidden fitted/>
                                                    {mapType === 'previouslocations' ? <Container textAlign='center'>
                                                        <Button.Group>
                                                            <DatePicker
                                                                selected={query.startDate}
                                                                onChange={(date) => change({startDate: date})}
                                                                customInput={
                                                                    <DatePickerInput/>
                                                                }
                                                            />
                                                            <Button.Or text='to'/>
                                                            <DatePicker
                                                                selected={query.endDate}
                                                                onChange={(date) => change({endDate: date})}
                                                                customInput={
                                                                    <DatePickerInput/>
                                                                }
                                                            />
                                                        </Button.Group>{' '}
                                                    </Container> : null}

                                                    {mapType === 'locations' || mapType === 'previouslocations' ? <DetailsContainer
                                                        getAddress={props.getAddress}
                                                        isGettingAddress={props.devices.isGettingAddress}
                                                        device={getDevice(selected, mapType === 'locations' || mapType === 'previouslocations' ? props.devices.lastLocationsView : props.devices.locationsView)}
                                                        location={props.devices.lastLocationsView[getDeviceLocationIndex(selected, props.devices.lastLocationsView)]}
                                                        previousLocations={mapType === 'previouslocations' ? getPreviousMarkers() : []}
                                                        setLocationIndex={setLocationIndex}
                                                        mapType={mapType}
                                                        mobileView={mobileSized}
                                                        getCode={getRecoveryCode}
                                                        clearCode={clearRecoveryCode}
                                                        gettingCode={props.devices.isGettingRecoveryCode}
                                                        getUserDetails={props.getUserDetails}
                                                        gettingUserDetails={props.devices.isGettingUserDetails}
                                                        users={props.user.users}
                                                        loadingPreviousLocations={props.devices.isGettingLastWakeups}
                                                    /> : null}
                                                </div>
                                            </div>
                                        </motion.div> : null}
                                    </AnimatePresence>
                                </div> : null}
                            </Embed>
                            {/*<Segment>*/}



                            <Confirm
                                open={geofenceChanges}
                                header='Geofence Changes'
                                content={`You made some changes to the geofence called "${props.geofences.geofences[selectedGeofence] && props.geofences.geofences[selectedGeofence].name ? props.geofences.geofences[selectedGeofence].name : 'Untitled Geofence'}". Do you want to save them?`}
                                confirmButton="Save Changes"
                                onCancel={() => {
                                    revertGeofenceChanges();

                                    if(newIndex === null) setSelectedGeofence(null);

                                    if(newIndex !== null) {
                                        setNewSelected(newIndex);
                                        setNewIndex(null);
                                    }

                                    setGeofenceChanges(false)
                                }}
                                onConfirm={() => {
                                    const id = newIndex ? props.geofences.geofences[newIndex]._id : null;

                                    if(newIndex !== null) {
                                        setNewSelected(newIndex);
                                        setNewIndex(null);
                                        setCentered(true);
                                    }

                                    saveShapes();
                                    setGeofenceChanges(false);

                                    if (id) props.geofencesDevicesGet(id);
                                }}
                            />

                            <BaseModal
                                color='black'
                                content='Report as Stolen'
                                icon='crosshairs'
                                labelPosition='left'
                                title='Report as Stolen'
                                // closeButton={() => setReportStolen(null)}
                                open={!!reportStolen}
                                close={() => setReportStolen(null)}
                                onClose={onCloseModal}
                            >

                                <ReportStolen
                                    submit={props.deviceRecovery}
                                    buttonText='Report Stolen'
                                    color='black'
                                    deviceId={reportStolen}
                                    deviceName={getDeviceName(props.devices.devices, reportStolen)}
                                    error={null}
                                    loading={props.devices.isGettingRecoveryCode}
                                    close={() => {
                                        setReportStolen(null);
                                    }}
                                />
                            </BaseModal>

                            {/*</Segment>*/}

                        </Grid.Column>

                    </Grid>
                </Segment>
            </Container>
        </Responsive>
    );
};

const getDeviceName = (devices, _id) => {
    const device = devices.find(device => device._id === _id);
    if (device && device.name) return device.name;
};

function degToCompass(num) {
    var val = Math.floor((num / 22.5) + 0.5);
    var arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[(val % 16)];
}

const DetailsContainer = (props) => {
    const [address, setAddress] = useState('');
    const [requested, setRequested] = useState([]);

    const getUser = (id) => {
        if(!id) return;
        const user = props.users.find(user => user._id === id);
        const userRequested = requested.find(requested => requested === id);
        if(!user && !userRequested) {
            setRequested([...requested, id]);
            props.getUserDetails(id);
        }
    };

    useEffect(() => {
        getUser(props.location.wakeups[0].wakeupsUserId)
    });

    const getUserName = (id) => {
        if(!id) return '';
        const user = props.users.find(user => user._id === id);
        if(!user) return '';
        return user.firstName + ' ' + user.lastName;
    }

    const getAddress = () => {
        return props.location &&
        props.location &&
        props.location.wakeups &&
        props.location.wakeups.length > 0 ? props.location.wakeups[0].address : null;
    };

    const createRow = (row, index) => {
        return <Table.Row key={`prevloc-${row.text}`} onClick={() => props.setLocationIndex(index)} style={{cursor: 'pointer'}}>
            <Table.Cell>{row.text}</Table.Cell>
            <Table.Cell>{moment(row.date).format('MM-DD-YYYY HH:mm')}</Table.Cell>
            <Table.Cell>{row.address}</Table.Cell>
            <Table.Cell>{row.speed} MPH</Table.Cell>
            <Table.Cell>{degToCompass(row.direction)}</Table.Cell>
            <Table.Cell>{row.locationMethod.toUpperCase()}</Table.Cell>
            <Table.Cell>{row.operatingMode}</Table.Cell>
        </Table.Row>
    };

    const createRows = (data) => {
        const rows = [];
        data.forEach((row, index) => {
            rows.push(createRow(row, index))
        });

        return rows;
    };

    const createRowMobile = (row) => {
        return <Table.Row key={`prevloc-${row.key}-${row.text}`}>
            <Table.Cell>{row.heading}</Table.Cell>
            <Table.Cell>{row.data}</Table.Cell>
        </Table.Row>
    };

    const createRowsMobile = (row) => {
        const rows = [];
        const rowData = [
            {
                heading: 'Location',
                data: row.text,
                key: 'loc'
            },
            {
                heading: 'Date/Time',
                data: moment(row.date).format('LLLL'),
                key: 'dat'
            },
            {
                heading: 'Address',
                data: row.address,
                key: 'add'
            },
            {
                heading: 'Speed',
                data: row.speed + 'MPH',
                key: 'spd'
            },
            {
                heading: 'Direction',
                data: degToCompass(row.direction),
                key: 'dir'
            },
            {
                heading: 'Location Type',
                data: row.locationMethod.toUpperCase(),
                key: 'loct'
            }
        ];

        rowData.forEach(row => {
            rows.push(createRowMobile(row))
        });

        return <Table unstackable celled striped definition>
            <Table.Body>{rows}</Table.Body>
        </Table>
    };

    const createMobilePrevious = (data) => {
        return data.map(table => createRowsMobile(table));
    };

    const recoveryCodeView = (
        <Grid.Row>
            <Grid.Column width={12}>

                {props.device && !props.device.recoveryCode ?
                    <Button color='red' onClick={() => props.getCode(props.device._id)} circular icon
                            labelPosition='left' loading={props.gettingCode}>
                        <Icon name='alarm'/>
                        <Button.Content>Vehicle or asset missing?</Button.Content>
                    </Button> : null}

                {props.device && props.device.recoveryCode ?
                    <>
                        <Button inverted color='red' circular labelPosition='left' icon='alarm mute'
                                content='Cancel Recovery Code' onClick={() => props.clearCode(props.device._id)}
                                loading={props.gettingCode}/>

                        <Segment raised>
                            <CodeTable className={'recovery-table'} basic='very' unstackable textAlign='center'>
                                <Table.Body key={'recover-code'}>
                                    <Table.Row>
                                        <Table.Cell key={'recover-0'} style={{
                                            fontSize: '22px',
                                            fontWeight: 'inherit !important',
                                            color: 'black !important'
                                        }}>{props.device.recoveryCode[0]}</Table.Cell>
                                        <Table.Cell key={'recover-1'}
                                                    style={{fontSize: '22px'}}>{props.device.recoveryCode[1]}</Table.Cell>
                                        <Table.Cell key={'recover-2'}
                                                    style={{fontSize: '22px'}}>{props.device.recoveryCode[2]}</Table.Cell>
                                        <Table.Cell key={'recover-3'}
                                                    style={{fontSize: '22px'}}>{props.device.recoveryCode[3]}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </CodeTable>
                        </Segment>
                    </>
                    : null}

                <Divider/>
            </Grid.Column>

        </Grid.Row>
    );

    const locationsDetails = props.location ?
        <Grid textAlign='center'>
            {/*{recoveryCodeView}*/}

            <Grid.Row>
                <Grid.Column width={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                    <Table basic="very" compact='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell collapsing><HeaderCellText>ID:</HeaderCellText></Table.Cell>
                                <Table.Cell collapsing>{props.location._id}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell collapsing><HeaderCellText>Name:</HeaderCellText></Table.Cell>
                                <Table.Cell collapsing>{props?.location?.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell collapsing><HeaderCellText>Connection Type:</HeaderCellText></Table.Cell>
                                <Table.Cell collapsing>
                                    {props.location && props.location.wakeups && props.location.wakeups[0].locationMethod ? props.location.wakeups[0].locationMethod.toUpperCase() : ''}
                                </Table.Cell>
                            </Table.Row>
                            {props.location.wakeups[0].locationMethod === 'gnss' ?
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        <HeaderCellText>Speed:</HeaderCellText>
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        {props.location.wakeups[0][props.location.wakeups[0].locationMethod].speed} MPH
                                    </Table.Cell>
                                </Table.Row> : null}
                            {props.location.wakeups[0].locationMethod === 'gnss' ?
                                <Table.Row>
                                    <Table.Cell collapsing><HeaderCellText>Direction:</HeaderCellText></Table.Cell>
                                    <Table.Cell collapsing>
                                        {degToCompass(props.location.wakeups[0][props.location.wakeups[0].locationMethod].direction)}
                                    </Table.Cell>
                                </Table.Row> : null}
                            <Table.Row>
                                <Table.Cell><HeaderCellText>Address:</HeaderCellText></Table.Cell>
                                <Table.Cell>
                                    {props.location.wakeups[0].address ? props.location.wakeups[0].address : ''}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><HeaderCellText>Set Wake Ups:</HeaderCellText></Table.Cell>
                                <Table.Cell>
                                    {props.location.wakeups[0].wakeupsUserId ? getUserName(props.location.wakeups[0].wakeupsUserId) : ''}
                                </Table.Cell>
                            </Table.Row>
                            {props.location.wakeups[0].operatingMode === 'Tracking' ? <Table.Row>
                                <Table.Cell><HeaderCellText>Authorised Locate Mode:</HeaderCellText></Table.Cell>
                                <Table.Cell>
                                    {props.location.wakeups[0].locateUserId ? getUserName(props.location.wakeups[0].locateUserId) : ''}
                                </Table.Cell>
                            </Table.Row> : null}
                        </Table.Body>
                    </Table>
                    <Divider hidden/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        : <Header as='h4' textAlign='center'>No device selected.</Header>;

    const previousLocationsDetails = <div>
        {/*<Grid textAlign='center'>*/}
        {/*    {recoveryCodeView}*/}
        {/*</Grid>*/}
        {props.previousLocations.length > 0 ?
            <Grid columns={2}>
                <Grid.Row>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <ul style={{listStyleType: 'none', fontWeight: 'bold', color: 'darkGrey', textAlign: 'left'}}>
                            <li>Device ID:</li>
                            <li>Name:</li>
                        </ul>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <ul style={{listStyleType: 'none', marginTop: "15px", lineHeight: "1.5", fontWeight: 'bold', textAlign: 'right'}}>
                            <li>{props.location._id}</li>
                            <li>{props?.location?.name}</li>
                        </ul>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            : <Header as='h4' textAlign='center'>
                <Divider hidden/>
                No results.
            </Header>}
        {!props.mobileView && props.previousLocations.length > 0 && !props.loadingPreviousLocations ? <Table striped selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell>Date/Time</Table.HeaderCell>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>Speed</Table.HeaderCell>
                    <Table.HeaderCell>Direction</Table.HeaderCell>
                    <Table.HeaderCell>Location Type</Table.HeaderCell>
                    <Table.HeaderCell>Operating Mode</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {createRows(props.previousLocations)}
            </Table.Body>
        </Table> : null}
        {props.loadingPreviousLocations ? <Loader style={{top: '75%'}} active/> : null}
        {props.mobileView && props.previousLocations.length > 0 ? createMobilePrevious(props.previousLocations) : null}
    </div>;

    return props.mapType === 'previouslocations' ? previousLocationsDetails : locationsDetails;

};

const createMapOptions = (maps) => {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
        // zoomControlOptions: {
        //     position: maps.ControlPosition.RIGHT_BOTTOM,
        //     style: maps.ZoomControlStyle.SMALL
        // },
        // mapTypeControlOptions: {
        //     position: maps.ControlPosition.TOP_LEFT
        // },
        // mapTypeControl: true,
        // streetViewControl: true,
        // scaleControl: true
    };
};

let defaultCenter = {
    lat: 53.697027,
    lng: -2.297662,
};

let success = position => {
    const currentPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
    };
    defaultCenter = currentPosition;
};

const MapContainer = (props) => {
    const navigator = window.navigator;
    navigator.geolocation.getCurrentPosition(success);
    const getCenter = () => {
        if(props.mapType !== 'previouslocations') {
            return {
                lat: Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat,
                lng: Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng
            };
        }

        if(props.previousLocations && props.previousLocations.length > 0 && props.mapType === 'previouslocations') {
            return {
                lat: props.previousLocations[props.previousLocationIndex || 0].lat,
                lng: props.previousLocations[props.previousLocationIndex || 0].lng
            };
        }
    }

    const [defaultProps, setDefaultProps] = useState({
        center: getCenter(),
        zoom: 15
    });

    const locationsExist = !!(Object.keys(props.locations).length > 0);

    // TIME ZONES
    // console.log('*******************: ', props.locations);
    // console.log(props.locations.date);
    //
    // console.log(moment(props.locations.date).local().format('YYYY-MM-DD HH:mm:ss'));

    const getLat = () => {
        if(props.mapType !== 'previouslocations') {
            return locationsExist ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat
        }

        if(props.mapType === 'previouslocations') {
            return props?.previousLocations?.length > 0 ? props.previousLocations[props.previousLocationIndex || 0].lat : defaultCenter.lat
        }
    }

    const getLng = () => {
        if(props.mapType !== 'previouslocations') {
            return locationsExist ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng
        }

        if(props.mapType === 'previouslocations') {
            return props?.previousLocations?.length > 0 ? props.previousLocations[props.previousLocationIndex || 0].lng : defaultCenter.lng
        }
    }
    return (
        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
            {/*<pre>{JSON.stringify(locationsExist ? props.locations[props.locations.locationMethod] : defaultCenter, null, 2)}</pre>*/}
            <Map icon={Icon}
                // markers={[{lat: locationsExist && props.locations[props.locations.locationMethod].latitude, lng: locationsExist && props.locations[props.locations.locationMethod].longitude }]}
                 markers={props.markers}
                 previousLocations={props.previousLocations}
                 proximities={props.proximities}
                 proximityData={props.proximityData}
                 setProximityData={props.setProximityData}
                 defaultZoom={defaultProps.zoom}
                 center={defaultProps.center}
                 lat={getLat()}
                 lng={getLng()}
                 geofences={props.geofences}
                 addGeofence={props.addGeofence}
                 removeGeofence={props.removeGeofence}
                 updateGeofence={props.updateGeofence}
                 centered={props.centered}
                 setCentered={props.setCentered}
                 selectedDevices={props.selectedDevices}
                 selectedGeofence={props.selectedGeofence}
                 setSelectedGeofence={props.setSelectedGeofence}
                 saveGeofences={props.saveGeofences}
                 saveGeofenceDevices={props.saveGeofenceDevices}
                 getGeofenceDevices={props.getGeofenceDevices}
                 deleteGeofence={props.deleteGeofence}
                 mapType={props.mapType}
                 isTracking={props.isTracking}
                 getProximity={props.getProximity}
                 creationStep={props.creationStep}
                 setCreationStep={props.setCreationStep}
                 geofenceEditMode={props.geofenceEditMode}
                 onSelected={props.onSelected}
                 clearSelectedGeofence={props.clearSelectedGeofence}
                 saveShapes={props.saveShapes}

                 format={props.format}
            />
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        devices: state.devices,
        geofences: state.geofences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        groupCreate: (payload) => dispatch(groupCreate(payload)),
        groupUpdate: (payload) => dispatch(groupUpdate(payload)),
        locationsGet: (payload) => dispatch(locationsGet(payload)),
        devicesGet: () => dispatch(devicesGet()),
        lastWakeupsGet: () => dispatch(lastWakeupsGet()),
        lastLocationsGet: (payload) => dispatch(lastLocationsGet(payload)),
        getAddress: (payload) => dispatch(addressGet(payload)),
        geofencesGet: () => dispatch(geofencesGet()),
        addGeofence: (payload) => dispatch(addGeofenceRequest(payload)),
        removeGeofence: () => dispatch(removeUnsavedGeofences()),
        updateGeofence: (payload) => dispatch(updateGeofenceRequest(payload)),
        addDevicetoGeofence: (payload) => dispatch(updateGeofenceDeviceRequest(payload)),
        saveGeofences: (payload) => dispatch(geofencesSave(payload)),
        saveGeofenceDevices: (payload) => dispatch(saveGeofenceDevices(payload)),
        geofencesDevicesGet: (payload) => dispatch(geofencesDevicesGet(payload)),
        deleteGeofence: (payload) => dispatch(geofencesRemove(payload)),
        deviceRecovery: (payload) => dispatch(deviceRecovery(payload)),
        deviceClearRecovery: (payload) => dispatch(deviceClearRecovery(payload)),
        getUserDetails: (payload) => dispatch(userDetailsGet(payload)),
        resetActioned: () => dispatch(resetActioned()),
        groupsSetPersisted: (payload) => dispatch(setPersistedGroups(payload)),
        clearDeviceErrors: () => dispatch(clearDeviceErrors()),
        proximityGet: (payload) => dispatch(proximityGet(payload)),
        getAssignedDevices: (payload) => dispatch(geofencesAssignedDevicesGet(payload)),
        getUnassignedDevices: (payload) => dispatch(geofencesUnassignedDevicesGet(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
