import React, {useLayoutEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Divider,
    Header,
    Grid,
    Button,
    Menu, Confirm, Pagination, Input,
} from 'semantic-ui-react';
import {installerGet, userGet, installerInvite, clearError, deleteUser, approveInstaller} from "../../actions/user";
import {resetActioned} from '../../actions/ui';
import {userGroupAdd, groupsGet, userGroupsGet, groupCreate} from "../../actions/groups";
import InviteUser from '../invite-user';
import ItemsList from '../items-list';
import BaseModal from '../base-modal';
import EmptyBoard from '../empty-board';
import AddToGroup from "../add-to-group";
import RemoveItem from "../remove-item";
import {getLocaleString} from "../../languages/localised";
import ConfirmItem from "../confirm-item";
import {isProtected} from "../../utils/protected-component/protected-component";
import ProtectedComponent from "../../utils/protected-component";

const ManageInstallerUsers = (props) => {
    const limit = 20;
    const [usertype, setUsertype] = useState(props.usertype);
    let [modals, setModal] = useState({});
    let [searchValue, setSearchValue] = useState('');
    const [pagination, setPagination] = useState({skip: 0, limit: limit});
    const [currentPage, setCurrentPage] = useState(1);
    let [sortByValue, setSortByValue] = useState(null);
    let [sortValues, setSortValues] = useState({
        'username': {
            name: 'username',
            direction: 'asc'
        },
        'firstName': {
            name: 'firstName',
            direction: 'asc'
        },
        'lastName': {
            name: 'lastName',
            direction: 'asc'
        },
        'email': {
            name: 'email',
            direction: 'asc'
        },
        'userStatus': {
            name: 'userStatus',
            direction: 'asc'
        }
    });
    const [selectedSort, setSelectedSort] = useState(null);

    useLayoutEffect(() => {
        props.userGet();
        props.installerGet({skip: pagination.skip, limit: pagination.limit, usertype: usertype});
        props.groupsGet(false);
    }, []);

    const clearSearch = () => {
        setCurrentPage(1);
        setPagination({skip: 0, limit: limit});
        setSearchValue('');
        props.installerGet({skip: pagination.skip, limit: pagination.limit, usertype: usertype});
    };

    const change = (e) => {
        setSearchValue(e.target.value);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitNewSearch();
        }
    };

    const submitNewSearch = (sortValue, groups) => {
        setCurrentPage(1);
        props.installerGet({skip: pagination.skip, limit: pagination.limit, usertype: usertype, search: searchValue});
    }

    const renderPagination = props.user && props.user.installers && props.user.installersData.documentCount && props.user.installersData.documentCount >= limit && (<Container textAlign="right">
                    <span style={{marginRight: 20}}>
                        {pagination.skip + 1}-{pagination.skip + pagination.limit < props.user.installersData.documentCount ?
                        pagination.skip + pagination.limit
                        : props.user.installersData.documentCount } of {props.user.installersData.documentCount || 0}
                    </span>
        <Pagination
            style={{marginBottom: 15}}
            boundaryRange={1}
            activePage={currentPage}
            ellipsisItem={null}
            firstItem={undefined}
            lastItem={undefined}
            prevItem={undefined}
            nextItem={undefined}
            siblingRange={1}
            totalPages={Math.ceil(props.user.installersData.documentCount / limit)}
            onPageChange={(e, {activePage}) => {
                setCurrentPage(activePage);
                props.installerGet({skip: ((activePage - 1) * limit), limit: limit, usertype: usertype, search: searchValue, sortByValue: sortByValue});
            }}
        />
    </Container>)

    const executeAction = data => {
        if (data.action && data.action === 'approve') {
            setModal({...modals, [data.action]: true, data});
        }

        if (data.action && data.action === 'groups') {
            props.userGroupsGet(data._id);
            setModal({...modals, [data.action]: true, data});
        }

        if (data.action && data.action === 'delete') {
            setModal({...modals, [data.action]: true, data});
        }
    };

    const onCloseModal = () => {
        props.clearErrors();
        props.resetActioned();
        setModal({});
    };

    const getStatus = (data) => {
        const colors = {
            'Approved': 'green',
            'Pending': 'orange'
        };

        const status = data === 'approved' ? 'Approved' : 'Pending';

        return <Button icon color={colors[status]} compact size='mini'>{getLocaleString(status)}</Button>;
    };

    const protectedMenu = (key) => {
        const protectedOptions = {
            approve: {
                permissions: ['users.installer_user_approve.can_use'],
                groups: ['local_admin', 'admin']
            },
            delete: {
                permissions: ['users.installer_user_delete.can_use'],
                groups: ['local_admin', 'admin']
            },

        };

        return isProtected(key, props.user, protectedOptions);
    }

    const onSort = (sortBy, selected) => {
        setCurrentPage(1);

        const direction = sortValues[sortBy].direction === 'asc' ? 'desc' : 'asc';
        setSelectedSort(selected);
        setSortValues({...sortValues, [sortBy]: {...sortValues[sortBy], direction}});
        setSortByValue({sortBy, direction});
        props.installerGet({skip: 0, limit: pagination.limit, sortByValue: {sortBy, direction}, usertype: usertype, search: searchValue });
    };

    return (
        <Container>
            {/*-- Devices Admin --*/}
            <Container>
                {(searchValue && props.user.installers && props.user.installers.length === 0) || props.user.installers && props.user.installers.length > 0 ?
                    <Container fluid textAlign='right'>
                        <Grid columns={2} padded>
                            <Grid.Column>
                                <Container style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 5}} fluid>

                                        <Input
                                            type='text'
                                            placeholder='Search Users...'
                                            icon={searchValue ? {name: 'cancel', link: true, onClick: clearSearch} : null}
                                            style={{
                                                marginTop: -2,
                                                marginRight: 5,
                                            }}
                                            value={searchValue}
                                            onChange={change}
                                            onKeyDown={onKeyDown}
                                        />
                                        <Button color='black' content='Search' onClick={submitNewSearch}/>

                                </Container>
                            </Grid.Column>
                            <Grid.Column>
                        <ProtectedComponent
                            permissions={['users.invite_installer_user.can_use']}
                            groups={['local_admin', 'admin']}
                        >
                            <Button
                                color='black'
                                content='Share Link'
                                icon='add'
                                labelPosition='right'
                                onClick={() => {
                                    props.clearErrors();
                                    props.resetActioned();
                                    setModal({
                                        ...modals,
                                        add: true
                                    });
                                }}
                            />
                        </ProtectedComponent>
                            </Grid.Column>
                        </Grid>
                    </Container> : null}


                <Divider hidden/>

                {renderPagination}

                {props.user.installers && props.user.installers.length > 0 ? <ItemsList
                    justify='left'
                    headings={[
                        {
                            title: 'Username',
                            field: 'username',
                            sortName: 'username',
                            sortDirection: sortValues['username'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'First Name',
                            field: 'firstName',
                            sortName: 'firstName',
                            sortDirection: sortValues['firstName'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            sortName: 'lastName',
                            sortDirection: sortValues['lastName'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Email Address',
                            field: 'email',
                            sortName: 'email',
                            sortDirection: sortValues['email'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Status',
                            field: 'userStatus',
                            function: getStatus,
                            sortName: 'userStatus',
                            sortDirection: sortValues['userStatus'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        }
                    ]}

                    items={props.user.installers}

                    selectable

                    actions={[
                        // {
                        //     key: 'edit',
                        //     icon: 'edit',
                        //     text: 'Edit User Details',
                        //     color: 'teal',
                        //     action: 'edit'
                        // },
                        {
                            key: 'approve',
                            icon: 'check circle',
                            text: 'Approve User',
                            color: 'green',
                            action: 'approve',
                            selectable: false,
                            protected: protectedMenu,
                        },
                        // {
                        //     key: 'groups',
                        //     icon: 'users',
                        //     text: 'Edit User Groups',
                        //     color: 'teal',
                        //     action: 'groups',
                        //     selectable: true,
                        //     isParent: props.user.isParent && !props.user.isAdmin,
                        //     protected: true,
                        // },
                        {
                            key: 'delete',
                            icon: 'delete',
                            text: 'Remove User',
                            color: 'red',
                            action: 'delete',
                            selectable: false,
                            protected: protectedMenu,
                        }
                    ]}

                    callback={executeAction}
                /> : <EmptyBoard loader={props.user.isLoadingInstallers} icon='users'
                                 message={searchValue ? 'No results.' : 'You haven&apos;t added any installers.'} button={!searchValue ?

                    <Button
                        color='black'
                        content='Share Link'
                        icon='add'
                        labelPosition='right'
                        onClick={() => {
                            props.clearErrors();
                            props.resetActioned();
                            setModal({
                                ...modals,
                                add: true
                            });
                        }}
                        size='large'
                    /> : null
                }/>}

            </Container>

            <Divider hidden/>

            {renderPagination}

            <Divider hidden/>

            {/*<BaseModal color='red' content='Remove' icon='delete' labelPosition='left' title='Remove User' open={modals.delete} onClose={() => setModal({})}>DELETE</BaseModal>*/}

            <BaseModal
                color='black'
                content='Approve'
                icon='check circle'
                labelPosition='left'
                title='Approve Installer'
                open={modals.approve}
                close={() => setModal({...modals, approve: false})}
                onClose={() => setModal({})}>
                {
                    modals.data ?
                        <ConfirmItem
                            submit={props.approveUser}
                            itemType={'user'}
                            itemNames={[`${modals.data.firstName} ${modals.data.lastName} - ${modals.data.email}`]}
                            approveItems={{installerId: modals.data._id}}
                            icon='check circle'
                            title='Approve Installer'
                            buttonText='Approve'
                            color='black'
                            loading={props.user.isApprovingUser}
                            close={() => {
                                setModal({...modals, approve: false});
                                // props.clearGroupErrors();
                            }}
                            error={props.user.error}
                        /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Delete'
                icon='delete'
                labelPosition='left'
                title='Remove User'
                open={modals.delete}
                close={() => setModal({...modals, delete: false})}
                onClose={() => setModal({})}>
                {
                    modals.data ?
                        <RemoveItem
                            submit={props.deleteUser}
                            itemType={'user'}
                            itemNames={[`${modals.data.firstName} ${modals.data.lastName} - ${modals.data.email}`]}
                            removeItems={{userDeletionId: modals.data._id}}
                            icon='delete'
                            title='Delete User'
                            buttonText='Delete'
                            color='black'
                            loading={props.user.isDeletingUser}
                            close={() => {
                                setModal({...modals, delete: false});
                                // props.clearGroupErrors();
                            }}
                            error={props.user.error}
                        /> : null}
            </BaseModal>

            <BaseModal color='black' content='Share Installer User Sign-Up Link' icon='add' labelPosition='right' title='Share Installer User Sign-Up Link'
                       open={modals.add}
                       close={() => setModal({...modals, add: false})}
                       onClose={onCloseModal}>
                <InviteUser
                    submit={props.installerInvite}
                    icon='add'
                    title='Share Installer User Sign-Up Link'
                    buttonText='Share Link'
                    color='black'
                    error={props.user.error}
                    loading={props.user.isInvitingUser}
                    usertype={usertype}
                    close={() => {
                        setModal({...modals, add: false});
                        // onCloseModal();
                    }}
                />
            </BaseModal>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        installerGet: (payload) => dispatch(installerGet(payload)),
        installerInvite: (payload) => dispatch(installerInvite(payload)),
        userGet: () => dispatch(userGet()),
        clearErrors: () => dispatch(clearError()),
        userGroupAdd: (payload) => dispatch(userGroupAdd(payload)),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        userGroupsGet: (payload) => dispatch(userGroupsGet(payload)),
        groupCreate: (payload) => dispatch(groupCreate(payload)),
        resetActioned: () => dispatch(resetActioned()),
        deleteUser: (payload) => dispatch(deleteUser(payload)),
        approveUser: (payload) => dispatch(approveInstaller(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInstallerUsers);
