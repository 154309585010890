
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_INVITE_REQUEST = 'USER_INVITE_REQUEST';
export const USER_INVITE_SUCCESS = 'USER_INVITE_SUCCESS';
export const USER_INVITE_FAILURE = 'USER_INVITE_FAILURE';

export const USER_SUBMIT_REQUEST = 'USER_SUBMIT_REQUEST';
export const USER_SUBMIT_SUCCESS = 'USER_SUBMIT_SUCCESS';
export const USER_SUBMIT_FAILURE = 'USER_SUBMIT_FAILURE';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_AGREE_TERMS_REQUEST = 'USER_AGREE_TERMS_REQUEST';
export const USER_AGREE_TERMS_SUCCESS = 'USER_AGREE_TERMS_SUCCESS';
export const USER_AGREE_TERMS_FAILURE = 'USER_AGREE_TERMS_FAILURE';

export const USER_RESET_REQUEST_REQUEST = 'USER_RESET_REQUEST_REQUEST';
export const USER_RESET_REQUEST_SUCCESS = 'USER_RESET_REQUEST_SUCCESS';
export const USER_RESET_REQUEST_FAILURE = 'USER_RESET_REQUEST_FAILURE';
export const USER_RESET_REQUEST_RESET = 'USER_RESET_REQUEST_RESET';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET';

export const USER_RESET_INVITE_STATE = 'USER_RESET_INVITE_STATE';

export const USER_GET_MESSAGES_REQUEST = 'USER_GET_MESSAGES_REQUEST';
export const USER_GET_MESSAGES_SUCCESS = 'USER_GET_MESSAGES_SUCCESS';
export const USER_GET_MESSAGES_FAILURE = 'USER_GET_MESSAGES_FAILURE';

export const USER_SEND_MESSAGE_REQUEST = 'USER_SEND_MESSAGE_REQUEST';
export const USER_SEND_MESSAGE_SUCCESS = 'USER_SEND_MESSAGE_SUCCESS';
export const USER_SEND_MESSAGE_FAILURE = 'USER_SEND_MESSAGE_FAILURE';

export const USER_GET_DETAILS_REQUEST = 'USER_GET_DETAILS_REQUEST';
export const USER_GET_DETAILS_SUCCESS = 'USER_GET_DETAILS_SUCCESS';
export const USER_GET_DETAILS_FAILURE = 'USER_GET_DETAILS_FAILURE';

export const LOGOUT = 'LOGOUT';

export const CHILDREN_REQUEST = 'CHILDREN_REQUEST';
export const CHILDREN_SUCCESS = 'CHILDREN_SUCCESS';
export const CHILDREN_FAILURE = 'CHILDREN_FAILURE';

export const CHILD_UPDATE_REQUEST = 'CHILD_UPDATE_REQUEST';
export const CHILD_UPDATE_SUCCESS = 'CHILD_UPDATE_SUCCESS';
export const CHILD_UPDATE_FAILURE = 'CHILD_UPDATE_FAILURE';

export const INSTALLER_REQUEST = 'INSTALLER_REQUEST';
export const INSTALLER_SUCCESS = 'INSTALLER_SUCCESS';
export const INSTALLER_FAILURE = 'INSTALLER_FAILURE';

export const INSTALLER_INVITE_REQUEST = 'INSTALLER_INVITE_REQUEST';
export const INSTALLER_INVITE_SUCCESS = 'INSTALLER_INVITE_SUCCESS';
export const INSTALLER_INVITE_FAILURE = 'INSTALLER_INVITE_FAILURE';

export const APPROVE_INSTALLER_USER_REQUEST = 'APPROVE_INSTALLER_USER_REQUEST';
export const APPROVE_INSTALLER_USER_SUCCESS = 'APPROVE_INSTALLER_USER_SUCCESS';
export const APPROVE_INSTALLER_USER_FAILURE = 'APPROVE_INSTALLER_USER_FAILURE';

export const GROUPS_REQUEST = 'GROUPS_REQUEST';
export const GROUPS_SUCCESS = 'GROUPS_SUCCESS';
export const GROUPS_FAILURE = 'GROUPS_FAILURE';

export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_FAILURE = 'GROUP_CREATE_FAILURE';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE';

export const GROUP_REMOVE_REQUEST = 'GROUP_REMOVE_REQUEST';
export const GROUP_REMOVE_SUCCESS = 'GROUP_REMOVE_SUCCESS';
export const GROUP_REMOVE_FAILURE = 'GROUP_REMOVE_FAILURE';

export const GROUP_ADD_CATEGORY_REQUEST = 'GROUP_ADD_CATEGORY_REQUEST';
export const GROUP_ADD_CATEGORY_SUCCESS = 'GROUP_ADD_CATEGORY_SUCCESS';
export const GROUP_ADD_CATEGORY_FAILURE = 'GROUP_ADD_CATEGORY_FAILURE';

export const GROUP_SET_BRANCH_REQUEST = 'GROUP_SET_BRANCH_REQUEST';
export const GROUP_SET_BRANCH_SUCCESS = 'GROUP_SET_BRANCH_SUCCESS';
export const GROUP_SET_BRANCH_FAILURE = 'GROUP_SET_BRANCH_FAILURE';

export const GROUP_SET_TYPE_REQUEST = 'GROUP_SET_TYPE_REQUEST';
export const GROUP_SET_TYPE_SUCCESS = 'GROUP_SET_TYPE_SUCCESS';
export const GROUP_SET_TYPE_FAILURE = 'GROUP_SET_TYPE_FAILURE';

export const GROUP_UNSET_BRANCH_REQUEST = 'GROUP_UNSET_BRANCH_REQUEST';
export const GROUP_UNSET_BRANCH_SUCCESS = 'GROUP_UNSET_BRANCH_SUCCESS';
export const GROUP_UNSET_BRANCH_FAILURE = 'GROUP_UNSET_BRANCH_FAILURE';

export const CLEAR_GROUP_ERRORS = 'CLEAR_GROUP_ERRORS';

export const DEVICE_ADD_REQUEST = 'DEVICE_ADD_REQUEST';
export const DEVICE_ADD_SUCCESS = 'DEVICE_ADD_SUCCESS';
export const DEVICE_ADD_FAILURE = 'DEVICE_ADD_FAILURE';

export const DEVICE_REMOVE_REQUEST = 'DEVICE_REMOVE_REQUEST';
export const DEVICE_REMOVE_SUCCESS = 'DEVICE_REMOVE_SUCCESS';
export const DEVICE_REMOVE_FAILURE = 'DEVICE_REMOVE_FAILURE';

export const DEVICE_UPDATE_REQUEST = 'DEVICE_UPDATE_REQUEST';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const DEVICE_UPDATE_FAILURE = 'DEVICE_UPDATE_FAILURE';

export const DEVICES_GET_REQUEST = 'DEVICES_GET_REQUEST';
export const DEVICES_GET_SUCCESS = 'DEVICES_GET_SUCCESS';
export const DEVICES_GET_FAILURE = 'DEVICES_GET_FAILURE';

export const DEVICES_CLEARALERT_REQUEST = 'DEVICES_CLEARALERT_REQUEST';
export const DEVICES_CLEARALERT_SUCCESS = 'DEVICES_CLEARALERT_SUCCESS';
export const DEVICES_CLEARALERT_FAILURE = 'DEVICES_CLEARALERT_FAILURE';

export const DEVICES_SET_FAVOURITE_REQUEST = 'DEVICES_SET_FAVOURITE_REQUEST';
export const DEVICES_SET_FAVOURITE_SUCCESS = 'DEVICES_SET_FAVOURITE_SUCCESS';
export const DEVICES_SET_FAVOURITE_FAILURE = 'DEVICES_SET_FAVOURITE_FAILURE';

export const DEVICES_WAKEUPTIMES_UPDATE_REQUEST = 'DEVICES_WAKEUPTIMES_UPDATE_REQUEST';
export const DEVICES_WAKEUPTIMES_UPDATE_SUCCESS = 'DEVICES_WAKEUPTIMES_UPDATE_SUCCESS';
export const DEVICES_WAKEUPTIMES_UPDATE_FAILURE = 'DEVICES_WAKEUPTIMES_UPDATE_FAILURE';

export const DEVICES_WAKEUPDAYS_UPDATE_REQUEST = 'DEVICES_WAKEUPDAYS_UPDATE_REQUEST';
export const DEVICES_WAKEUPDAYS_UPDATE_SUCCESS = 'DEVICES_WAKEUPDAYS_UPDATE_SUCCESS';
export const DEVICES_WAKEUPDAYS_UPDATE_FAILURE = 'DEVICES_WAKEUPDAYS_UPDATE_FAILURE';

export const DEVICE_GET_RECOVERY_CODE_REQUEST = 'DEVICE_GET_RECOVERY_CODE_REQUEST';
export const DEVICE_GET_RECOVERY_CODE_SUCCESS = 'DEVICE_GET_RECOVERY_CODE_SUCCESS';
export const DEVICE_GET_RECOVERY_CODE_FAILURE = 'DEVICE_GET_RECOVERY_CODE_FAILURE';

export const DEVICE_CLEAR_RECOVERY_CODE_REQUEST = 'DEVICE_CLEAR_RECOVERY_CODE_REQUEST';
export const DEVICE_CLEAR_RECOVERY_CODE_SUCCESS = 'DEVICE_CLEAR_RECOVERY_CODE_SUCCESS';
export const DEVICE_CLEAR_RECOVERY_CODE_FAILURE = 'DEVICE_CLEAR_RECOVERY_CODE_FAILURE';

export const DEVICES_GET_INSTALLATION_HISTORY_REQUEST = 'DEVICES_GET_INSTALLATION_HISTORY_REQUEST';
export const DEVICES_GET_INSTALLATION_HISTORY_SUCCESS = 'DEVICES_GET_INSTALLATION_HISTORY_SUCCESS';
export const DEVICES_GET_INSTALLATION_HISTORY_FAILURE = 'DEVICES_GET_INSTALLATION_HISTORY_FAILURE';

export const DEVICES_GET_NOTES_REQUEST = 'DEVICES_GET_NOTES_REQUEST';
export const DEVICES_GET_NOTES_SUCCESS = 'DEVICES_GET_NOTES_SUCCESS';
export const DEVICES_GET_NOTES_FAILURE = 'DEVICES_GET_NOTES_FAILURE';

export const DEVICES_SET_NOTE_REQUEST = 'DEVICES_SET_NOTE_REQUEST';
export const DEVICES_SET_NOTE_SUCCESS = 'DEVICES_SET_NOTE_SUCCESS';
export const DEVICES_SET_NOTE_FAILURE = 'DEVICES_SET_NOTE_FAILURE';

export const DEVICES_SELECT_ALL_REQUEST = 'DEVICES_SELECT_ALL_REQUEST';
export const DEVICES_SELECT_ALL_SUCCESS = 'DEVICES_SELECT_ALL_SUCCESS';
export const DEVICES_SELECT_ALL_FAILURE = 'DEVICES_SELECT_ALL_FAILURE';

export const CLEAR_DEVICE_ERRORS = 'CLEAR_DEVICE_ERRORS';

export const USERGROUP_ADD_REQUEST = 'USERGROUP_ADD_REQUEST';
export const USERGROUP_ADD_SUCCESS = 'USERGROUP_ADD_SUCCESS';
export const USERGROUP_ADD_FAILURE = 'USERGROUP_ADD_FAILURE';

export const USERGROUP_GET_REQUEST = 'USERGROUP_GET_REQUEST';
export const USERGROUP_GET_SUCCESS = 'USERGROUP_GET_SUCCESS';
export const USERGROUP_GET_FAILURE = 'USERGROUP_GET_FAILURE';

export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST';
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS';
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE';

export const LAST_LOCATIONS_GET_REQUEST = 'LAST_LOCATIONS_GET_REQUEST';
export const LAST_LOCATIONS_GET_SUCCESS = 'LAST_LOCATIONS_GET_SUCCESS';
export const LAST_LOCATIONS_GET_FAILURE = 'LAST_LOCATIONS_GET_FAILURE';

export const LAST_WAKEUPS_GET_REQUEST = 'LAST_WAKEUPS_GET_REQUEST';
export const LAST_WAKEUPS_GET_SUCCESS = 'LAST_WAKEUPS_GET_SUCCESS';
export const LAST_WAKEUPS_GET_FAILURE = 'LAST_WAKEUPS_GET_FAILURE';

export const ADDRESS_GET_REQUEST = 'ADDRESS_GET_REQUEST';
export const ADDRESS_GET_SUCCESS = 'ADDRESS_GET_SUCCESS';
export const ADDRESS_GET_FAILURE = 'ADDRESS_GET_FAILURE';

export const ALERTS_GET_REQUEST = 'ALERTS_GET_REQUEST';
export const ALERTS_GET_SUCCESS = 'ALERTS_GET_SUCCESS';
export const ALERTS_GET_FAILURE = 'ALERTS_GET_FAILURE';

export const ALERT_GET_REQUEST = 'ALERT_GET_REQUEST';
export const ALERT_GET_SUCCESS = 'ALERT_GET_SUCCESS';
export const ALERT_GET_FAILURE = 'ALERT_GET_FAILURE';

export const ALERTS_SET_ACTIONED_REQUEST = 'ALERTS_SET_ACTIONED_REQUEST';
export const ALERTS_SET_ACTIONED_SUCCESS = 'ALERTS_SET_ACTIONED_SUCCESS';
export const ALERTS_SET_ACTIONED_FAILURE = 'ALERTS_SET_ACTIONED_FAILURE';

export const REPORTS_GET_REQUEST = 'REPORTS_GET_REQUEST';
export const REPORTS_GET_SUCCESS = 'REPORTS_GET_SUCCESS';
export const REPORTS_GET_FAILURE = 'REPORTS_GET_FAILURE';

export const REPORT_CREATE_REQUEST = 'REPORT_CREATE_REQUEST';
export const REPORT_CREATE_SUCCESS = 'REPORT_CREATE_SUCCESS';
export const REPORT_CREATE_FAILURE = 'REPORT_CREATE_FAILURE';

export const REPORT_REMOVE_REQUEST = 'REPORT_REMOVE_REQUEST';
export const REPORT_REMOVE_SUCCESS = 'REPORT_REMOVE_SUCCESS';
export const REPORT_REMOVE_FAILURE = 'REPORT_REMOVE_FAILURE';

export const REPORT_RESET_CREATE_STATE = 'REPORT_RESET_CREATE_STATE';
export const CLEAR_REPORT_ERRORS = 'CLEAR_REPORT_ERRORS';

export const GEOFENCES_GET_REQUEST = 'GEOFENCES_GET_REQUEST';
export const GEOFENCES_GET_SUCCESS = 'GEOFENCES_GET_SUCCESS';
export const GEOFENCES_GET_FAILURE = 'GEOFENCES_GET_FAILURE';

export const GEOFENCES_SAVE_REQUEST = 'GEOFENCES_SAVE_REQUEST';
export const GEOFENCES_SAVE_SUCCESS = 'GEOFENCES_SAVE_SUCCESS';
export const GEOFENCES_SAVE_FAILURE = 'GEOFENCES_SAVE_FAILURE';

export const GEOFENCES_REMOVE_REQUEST = 'GEOFENCES_REMOVE_REQUEST';
export const GEOFENCES_REMOVE_SUCCESS = 'GEOFENCES_REMOVE_SUCCESS';
export const GEOFENCES_REMOVE_FAILURE = 'GEOFENCES_REMOVE_FAILURE';

export const GEOFENCES_DEVICES_REQUEST = 'GEOFENCES_DEVICES_REQUEST';
export const GEOFENCES_DEVICES_SUCCESS = 'GEOFENCES_DEVICES_SUCCESS';
export const GEOFENCES_DEVICES_FAILURE = 'GEOFENCES_DEVICES_FAILURE';
export const GEOFENCES_SAVE_DEVICES_REQUEST = 'GEOFENCES_SAVE_DEVICES_REQUEST';

export const GEOFENCES_UPDATE_REQUEST = 'GEOFENCES_UPDATE_REQUEST';
export const GEOFENCES_UPDATE_SUCCESS = 'GEOFENCES_UPDATE_SUCCESS';
export const GEOFENCES_ADD_REQUEST = 'GEOFENCES_ADD_REQUEST';
export const GEOFENCES_ADD_SUCCESS = 'GEOFENCES_ADD_SUCCESS';
export const GEOFENCES_ADD_DEVICE_REQUEST = 'GEOFENCES_ADD_DEVICE_REQUEST';
export const GEOFENCES_REMOVE_UNSAVED_REQUEST = 'GEOFENCES_REMOVE_UNSAVED_REQUEST';

export const GEOFENCES_GET_ASSIGNED_DEVICES_REQUEST = 'GEOFENCES_GET_ASSIGNED_DEVICES_REQUEST';
export const GEOFENCES_GET_ASSIGNED_DEVICES_SUCCESS = 'GEOFENCES_GET_ASSIGNED_DEVICES_SUCCESS';
export const GEOFENCES_GET_ASSIGNED_DEVICES_FAILURE = 'GEOFENCES_GET_ASSIGNED_DEVICES_FAILURE';

export const GEOFENCES_GET_UNASSIGNED_DEVICES_REQUEST = 'GEOFENCES_GET_UNASSIGNED_DEVICES_REQUEST';
export const GEOFENCES_GET_UNASSIGNED_DEVICES_SUCCESS = 'GEOFENCES_GET_UNASSIGNED_DEVICES_SUCCESS';
export const GEOFENCES_GET_UNASSIGNED_DEVICES_FAILURE = 'GEOFENCES_GET_UNASSIGNED_DEVICES_FAILURE';

export const UI_ACTIONBUTTON_SET_ACTIONED = 'UI_ACTIONBUTTON_SET_ACTIONED';
export const UI_ACTIONBUTTON_RESET_ACTIONED = 'UI_ACTIONBUTTON_RESET_ACTIONED';
export const UI_SET_MENU_ITEM = 'UI_SET_MENU_ITEM';

export const GET_URLS_REQUEST = 'GET_URLS_REQUEST';
export const GET_URLS_SUCCESS = 'GET_URLS_REQUEST';
export const GET_URLS_FAILURE = 'GET_URLS_FAILURE';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const ADD_TAG_FAILURE = 'ADD_TAG_FAILURE';

export const REMOVE_TAG_REQUEST = 'REMOVE_TAG_REQUEST';
export const REMOVE_TAG_SUCCESS = 'REMOVE_TAG_SUCCESS';
export const REMOVE_TAG_FAILURE = 'REMOVE_TAG_FAILURE';

export const UPDATE_DEVICE_TAGS_REQUEST = 'UPDATE_DEVICE_TAGS_REQUEST';
export const UPDATE_DEVICE_TAGS_SUCCESS = 'UPDATE_DEVICE_TAGS_SUCCESS';
export const UPDATE_DEVICE_TAGS_FAILURE = 'UPDATE_DEVICE_TAGS_FAILURE';

export const COUNT_TAG_REQUEST = 'COUNT_TAG_REQUEST';
export const COUNT_TAG_SUCCESS = 'COUNT_TAG_SUCCESS';
export const COUNT_TAG_FAILURE = 'COUNT_TAG_FAILURE';

export const COUNT_FILTER_REQUEST = 'COUNT_FILTER_REQUEST';
export const COUNT_FILTER_SUCCESS = 'COUNT_FILTER_SUCCESS';
export const COUNT_FILTER_FAILURE = 'COUNT_FILTER_FAILURE';

export const PROXIMITY_GET_REQUEST = 'PROXIMITY_GET_REQUEST';
export const PROXIMITY_GET_SUCCESS = 'PROXIMITY_GET_SUCCESS';
export const PROXIMITY_GET_FAILURE = 'PROXIMITY_GET_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const GET_PREFS_REQUEST = 'GET_PREFS_REQUEST';
export const GET_PREFS_SUCCESS = 'GET_PREFS_SUCCESS';
export const GET_PREFS_FAILURE = 'GET_PREFS_FAILURE';

export const SET_PREFS_REQUEST = 'SET_PREFS_REQUEST';
export const SET_PREFS_SUCCESS = 'SET_PREFS_SUCCESS';
export const SET_PREFS_FAILURE = 'SET_PREFS_FAILURE';

export const SET_PERSISTED_GROUP = 'SET_PERSISTED_GROUP';

export const CLEAR_DASHBOARD_MESSAGE = "CLEAR_DASHBOARD_MESSAGE";

export const GENERAL_ERROR = "GENERAL_ERROR";

