import React, {createRef, useLayoutEffect, useState} from 'react';
import { Button, Container, Divider, Segment, TextArea, Message } from 'semantic-ui-react';
import {noteSet, notesGet} from "../../actions/devices";
import {connect} from "react-redux";
import Moment from 'moment-timezone';
import styled from 'styled-components';

const Heading = styled.span`
  font-weight: bold;

`;

const DeviceNotes = (props) => {
    const [got, setGot] = useState(false);
    const [showAddNote, setShowAddNote] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [noteSaved, setNoteSaved] = useState(false);

    const contextRef = createRef();

    useLayoutEffect(() => {
        if(props.deviceId && !got) {
            props.deviceNotes(props.deviceId);
            setGot(true)
        }
    }, [got, setGot, props.deviceNotes, props.deviceId]);

    const formatTime = (time) => {
        return Moment.utc(time).local().format('LLL')
    };

    const getUser = (userId, users) => {
        if(!users || users && users.length === 0) return '';
        const user = users.find(installerItem => installerItem._id === userId);
        let userString = '';
        userString = userString + (user && user.firstName ? `${user.firstName} ` : '');
        userString = userString + (user && user.lastName ? `${user.lastName} ` : '');
        userString = userString + (user && user.email ? `(${user.email})` : '');

        return userString;
    };

    const formatNotes = (notesData, users) => {
          return notesData && notesData.length > 0 ? notesData.map((item, index) => {
              return <Segment basic key={`notes-${index}`} style={{marginTop: 10}}>
                  <p><Heading>Date Added:</Heading> {formatTime(item.date)}</p>
                  <p><Heading>Added By:</Heading> {getUser(item.userId, users)}</p>
                  {item.name ? <p><Heading>Device Name:</Heading> {item.name}</p> : null}
                  {item.plate ? <p><Heading>Reg No:</Heading> {item.plate.toUpperCase()}</p> : null}
                  {item.vin ? <p><Heading>VIN No:</Heading> {item.vin}</p> : null}
                  <p><Heading>Note:</Heading> {item.note}</p>
                  <Divider hidden/>
                  <Divider fitted/>
                  </Segment>
          }) : null;
    };


    const handleSaveNote = async () => {
        const payload = { deviceId: props.deviceId, note: noteText };
        await props.addNote(payload);

        if (props.devices.addNoteError) {
            setNoteSaved(false);
        } else {
            setNoteSaved(true);
            setShowAddNote(false);
            setNoteText('');
            setTimeout(() => setNoteSaved(false), 3000); // Reset success message after 3 seconds
        }
    };

    return (
        <Container>
            <div style={{
                position: 'absolute',
                top: 78,
                left: 0,
                zIndex: 10,
                background: 'rgb(241 241 241)',
                padding: '10px',
                width: '100%',
            }}>
                {showAddNote ? (
                    <Segment>
                        <TextArea
                            placeholder="Type your note here..."
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            disabled={props.devices.addingNote}
                            style={{width: '100%'}}
                        />
                        {props.devices.addingNote ? (
                            <Segment basic loading/>
                        ) : (
                            <Button primary onClick={handleSaveNote} disabled={!noteText.trim()}>
                                Save Note
                            </Button>
                        )}
                        {props.devices.addNoteError && (
                            <Message negative>
                                <Message.Header>Error Saving Note</Message.Header>
                                <p>{props.devices.addNoteError}</p>
                            </Message>
                        )}
                    </Segment>
                ) : (
                    <Button primary onClick={() => setShowAddNote(true)}>Add Note</Button>
                )}
                {noteSaved && (
                    <Message positive>
                        <Message.Header>Note Saved</Message.Header>
                    </Message>
                )}
            </div>
            <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                {props.devices.isGettingNotes ? (
                    <Segment basic loading/>
                ) : (
                    formatNotes(props.devices.notes[props.deviceId], props.devices.notesUsers)
                )}
            </div>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.devices
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deviceNotes: (payload) => dispatch(notesGet(payload)),
        addNote: (payload) => dispatch(noteSet(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotes);
