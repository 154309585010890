import React, { useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {Button, Form, Card, Divider, Container, Label, Responsive} from 'semantic-ui-react';
import {wakeupTimesUpdate} from "../../actions/devices";
import ActionButton from "../action-button";
import Countdown from '../countdown';
import styled from "styled-components";
import {isHibernateTimezone} from "../../utils/ui";

// TODO: Locked to 4 wake up times, revisit in future if we need more flexibility
const NUM_WAKEUPS = 4;

const WeekDayContainer = styled.div`
    display: flex; 
    align-items: center;
    padding-bottom: 20px;
`;

const WeekDayLabel = styled.span`
    font-weight: bold;
    font-size: 16px;
    padding-right: 10px;
`;

const WeekDay = styled.div`
  cursor: pointer;
  margin: 2.2px;    border: 1px grey solid;
    border-radius: 50%;
padding: 0.9px;
    width: 26px;
    height: 26px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
`;

const WeekDaySelected = styled.div`
  cursor: pointer;
  margin: 2.2px;    background-color: rgb(154, 90, 149);border: solid 1px rgb(154, 90, 149);
    border-radius: 50%;
padding: 0.9px;
    width: 26px;
    height: 26px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; font-weight: bold;
`;

const weekDaysData = [
    { value: 'M', selected: false },
    { value: 'T', selected: false },
    { value: 'W', selected: false },
    { value: 'T', selected: false },
    { value: 'F', selected: false },
    { value: 'S', selected: false },
    { value: 'S', selected: false },
];

const generateTimes = (times) => {
    const defaultObject = { enabled: false, time: '08:30' };

    // Create the time returned form the server as UTC and then convert to the local time
    if(times && times.length > 0) {
        return times.map((time) => time === null ? defaultObject : { enabled: true, time: Moment.utc(time, "h:mm a").local().format("HH:mm") });
    }

    // If the times array is empty, generate an empty wakeups array
    const emptyTimes = [];

    for(let i = 0; i < NUM_WAKEUPS; i++) {
        emptyTimes.push(defaultObject);
    }

    return emptyTimes;

};

const betweenTimes = (_startTime, _endTime, _time) => {
    const startTime = Number(_startTime.replace(':', ''));
    const endTime = Number(_endTime.replace(':', ''));
    const time = Number(_time.replace(':', ''));

    return time >= startTime && time <= endTime;
}

const WakeupTimes = (props) => {
    let [form, setForm] = useState({
        times: generateTimes(props.wakeupTimes)
    });

    // console.log('******************: ', props.pendingWakeupTimes);

    const handleSubmit = (e) => {
        // Create the time as the local time and then return the UTC version of the time to be stored on the server
        const timesObj = {
            times: form.times.map((time) => time.enabled ? Moment(time.time, "h:mm a").utc().format("HH:mm") : null),
            wakeupDays: props.wakeupDays || 'MTWTFSS',
            _ids: props.deviceIds
        };

        // console.log(timesObj);

        e.preventDefault();
        props.submit ? props.submit(timesObj) : props.wakeupTimesSubmit(timesObj);
    };

    const renderWeekDays = (days, weekDays) => {
        return days ? days.split('').map((day, index) => day !== 'X' ? <WeekDaySelected
            key={'wd'+index}>{weekDays[index].value}</WeekDaySelected> : <WeekDay key={'wd'+index} >{weekDays[index].value}</WeekDay>) : [];
    }

    return (
            <Form unstackable onSubmit={handleSubmit}>

                <WeekDayContainer>
                    <WeekDayLabel>Enabled Days: </WeekDayLabel>{renderWeekDays(props.wakeupDays, weekDaysData)}
                </WeekDayContainer>

                <Container>
                    Set the frequency and time of how many times your device wakes up in a 24hour period:
                </Container>
                <Divider hidden />
                {props.pendingWakeupTimes && props.pendingWakeupTimes.length > 0 ? <div>
                    <Divider />
                    <span style={{fontWeight: 'bold'}}>New wakeup times will be set in: <Label color='orange' basic><Countdown times={props.times} /></Label></span>
                    <Divider />
                    <b>Times to be set on next wakeup:</b> {props.pendingWakeupTimes.filter(time => time !== null)
                    .map(time => <Label key={time} color='orange' >{Moment.utc(time, "h:mm a").local().format("HH:mm")}</Label>)}
                        {/*<Divider hidden />*/}
                        {/*<span style={{fontSize: '0.7rem', fontStyle: 'italic'}}>Times to be set on next wakeup (UTC): {props.pendingWakeupTimes.filter(time => time !== null).map(time => {*/}
                        {/*    return <Label basic color='orange' size='tiny'>{time}</Label>*/}
                        {/*})}</span>*/}

                    {/*<Card.Group itemsPerRow='4'>*/}
                    {/*    <Card color='orange'>*/}
                    {/*        <Card.Content>*/}
                    {/*            <Card.Header textAlign='center'>Pending Wakeup Time 1</Card.Header>*/}
                    {/*        </Card.Content>*/}
                    {/*        <Card.Content textAlign='center'><Label color='orange' ><b>08:30</b></Label></Card.Content>*/}
                    {/*        <Card.Content Extra textAlign='center'><Label basic color='orange' size='tiny'>UTC: 07:30</Label></Card.Content>*/}
                    {/*    </Card>*/}
                    {/*</Card.Group>*/}
                    <Divider hidden />
                </div> : null}
                <WakeupCards configure={props.configure} form={form} setForm={setForm} />
                <Divider hidden />
                {/*<Button color='green' type='submit'>Update</Button>*/}
                <Container fluid textAlign='right'>
                    <ActionButton
                        color='black'
                        icon='edit'
                        buttonText='Save'
                        submit={handleSubmit}
                        loading={props.loading}
                        close={props.close}
                    />
                </Container>
            </Form>
    );
};

const WakeupCards = (props) => {
    const cards = [];

    const setEnabled = (index) => (e) => {
        e.preventDefault();
        props.setForm({
            ...props.form,
            times: props.form.times.map((time, i) => {
                return index === i ? { ...time, enabled: !time.enabled } : time;
            })});
    };

    const setTime = (time, index) => {
        const timeFormatted = Moment(time).format('HH:mm');
        props.setForm({
            ...props.form,
            times: props.form.times.map((time, i) => {
                return index === i ? { ...time, time: timeFormatted } : time;
            })});
    };

    for(let i = 0; i < NUM_WAKEUPS; i++) {
        const currTime = props.form.times[i];
        const defaultTime = timeToDate('08:30');
        cards.push(
            <Card key={i}>
                <Card.Content>
                    {props.configure && i === 0 ? <Card.Header>Config Wakeup</Card.Header> : <Card.Header>Wakeup Time {i+1}</Card.Header>}
                </Card.Content>
                <Card.Content extra textAlign='center'>
                    <Card.Description>
                        <Button toggle active={currTime.enabled} onClick={setEnabled(i)}>
                            { currTime.enabled ? 'Enabled' : 'Disabled' }
                        </Button>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Card.Description>
                        <Form.Field
                                control={DatePicker}
                                selected={timeToDate(currTime.time) || defaultTime}
                                onChange={time => betweenTimes('02:00', '22:00', Moment(time).format('HH:mm')) ? setTime(time, i) : null}
                                showTimeSelect
                                showTimeSelectOnly
                                popperPlacement='top-end'
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                    },
                                }}
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat='HH:mm'
                                timeFormat='HH:mm'
                                disabled={!currTime.enabled}
                                // minTime={isHibernateTimezone() ? new Date(0, 0, 0, 2, 0) : null}
                                // maxTime={isHibernateTimezone() ? new Date(0, 0, 0, 22, 0) : null}
                                minTime={null}
                                maxTime={null}
                                style={{ width: '100%' }}
                        />
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    }

    return (
        <>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <Card.Group itemsPerRow={4}>
                    {cards}
                </Card.Group>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <Card.Group itemsPerRow={2}>
                    {cards}
                </Card.Group>
            </Responsive>
        </>
    )
};

const timeToDate = (time) => {
    return time ? new Date(Moment(time, 'HH:mm')) : null;
};

const mapDispatchToProps = (dispatch) => {
    return {
        wakeupTimesSubmit: (payload) => dispatch(wakeupTimesUpdate(payload))
    }
};

export default connect(null, mapDispatchToProps)(WakeupTimes);
