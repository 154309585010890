import {
    DEVICE_ADD_REQUEST,
    DEVICE_ADD_SUCCESS,
    // DEVICE_ADD_FAILURE,
    DEVICE_UPDATE_REQUEST,
    DEVICE_UPDATE_SUCCESS,
    // DEVICE_UPDATE_FAILURE,
    DEVICES_GET_REQUEST,
    DEVICES_GET_SUCCESS,
    // DEVICES_GET_FAILURE,
    DEVICES_WAKEUPTIMES_UPDATE_REQUEST,
    DEVICES_WAKEUPTIMES_UPDATE_SUCCESS,
    LOCATIONS_GET_FAILURE,
    LOCATIONS_GET_REQUEST,
    LOCATIONS_GET_SUCCESS,
    LAST_LOCATIONS_GET_FAILURE,
    LAST_LOCATIONS_GET_REQUEST,
    LAST_LOCATIONS_GET_SUCCESS,
    LAST_WAKEUPS_GET_FAILURE,
    LAST_WAKEUPS_GET_REQUEST,
    LAST_WAKEUPS_GET_SUCCESS,
    ADDRESS_GET_SUCCESS,
    ADDRESS_GET_REQUEST,
    ADDRESS_GET_FAILURE,
    DEVICE_ADD_FAILURE,
    CLEAR_DEVICE_ERRORS,
    DEVICE_GET_RECOVERY_CODE_REQUEST,
    DEVICE_GET_RECOVERY_CODE_SUCCESS,
    DEVICE_GET_RECOVERY_CODE_FAILURE,
    DEVICE_CLEAR_RECOVERY_CODE_REQUEST,
    DEVICE_CLEAR_RECOVERY_CODE_SUCCESS,
    DEVICE_REMOVE_REQUEST,
    DEVICE_REMOVE_SUCCESS,
    DEVICE_REMOVE_FAILURE,
    DEVICES_GET_INSTALLATION_HISTORY_REQUEST,
    DEVICES_GET_INSTALLATION_HISTORY_SUCCESS,
    DEVICES_GET_INSTALLATION_HISTORY_FAILURE,
    DEVICES_GET_NOTES_REQUEST,
    DEVICES_GET_NOTES_SUCCESS,
    DEVICES_GET_NOTES_FAILURE,
    DEVICES_SET_NOTE_REQUEST,
    DEVICES_SET_NOTE_SUCCESS,
    DEVICES_SET_NOTE_FAILURE,
    UPDATE_DEVICE_TAGS_REQUEST,
    UPDATE_DEVICE_TAGS_SUCCESS,
    UPDATE_DEVICE_TAGS_FAILURE,
    DEVICES_WAKEUPDAYS_UPDATE_REQUEST,
    DEVICES_WAKEUPDAYS_UPDATE_FAILURE,
    DEVICES_WAKEUPDAYS_UPDATE_SUCCESS,
    DEVICES_CLEARALERT_REQUEST,
    DEVICES_CLEARALERT_FAILURE,
    DEVICES_CLEARALERT_SUCCESS,
    DEVICES_SET_FAVOURITE_REQUEST,
    DEVICES_SET_FAVOURITE_FAILURE,
    DEVICES_SET_FAVOURITE_SUCCESS,
    COUNT_FILTER_REQUEST,
    COUNT_FILTER_SUCCESS,
    COUNT_FILTER_FAILURE,
    DEVICES_SELECT_ALL_REQUEST,
    DEVICES_SELECT_ALL_SUCCESS,
    DEVICES_SELECT_ALL_FAILURE,
    PROXIMITY_GET_REQUEST,
    PROXIMITY_GET_SUCCESS,
    PROXIMITY_GET_FAILURE
} from '../actiontypes/types';
import {resetActioned, setActioned} from "./ui";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const devicesGetRequest = () => {
    return {
        type: DEVICES_GET_REQUEST
    }
};

export const devicesGetSuccess = (payload) => {
    return {
        type: DEVICES_GET_SUCCESS,
        payload
    }
};

const queryBuilder = (payload) => {
    const queryBuilder = [];

    if (payload && payload.search) queryBuilder.push(`search=${encodeURIComponent(payload.search)}`);
    if (payload && payload.override) queryBuilder.push(`override=1`);
    if (payload && payload.sort) queryBuilder.push(`sortby=${payload.sort.sortBy}&sortdirection=${payload.sort.direction}`);
    if (payload && payload.tagfilter && payload.tagfilter.length > 0) queryBuilder.push(`tags=${payload.tagfilter.join(',')}`);
    if (payload && payload.filters && payload.filters.length > 0) {
        payload.filters.forEach(filter => {
            queryBuilder.push(`${filter.name}=${filter.value}`);
        })
    }
    if(payload && payload.selectType) queryBuilder.push(`type=${payload.selectType}`);
    if (payload && payload.groups) queryBuilder.push(`groups=${payload.groups}`);
    if (payload && (payload.skip || payload.skip === 0)) queryBuilder.push(`skip=${payload.skip}`);
    if (payload && payload.limit) queryBuilder.push(`limit=${payload.limit}`);

    return queryBuilder.length > 0 ? '?' + queryBuilder.join('&') : '';
}

export const devicesGet = (payload) => {
    return (dispatch) => {
        dispatch(devicesGetRequest());

        const searchString = queryBuilder(payload);

        return fetch(`${ZZZAPI}/api/devices${searchString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    }
                },
                error => console.log('An error occurred: ', error))
            .then(json => dispatch(devicesGetSuccess(json)));
    }
};

export const deviceAddRequest = () => {
    return {
        type: DEVICE_ADD_REQUEST
    }
};

export const deviceAddSuccess = (payload) => {
    return {
            type: DEVICE_ADD_SUCCESS,
            payload
        }
};

export const deviceAddFailed = (payload) => {
    return {
            type: DEVICE_ADD_FAILURE,
            payload
        }
};

export const deviceAdd = (payload) => {
    return (dispatch) => {
        dispatch(deviceAddRequest());

        return fetch(`${ZZZAPI}/api/devices/assign`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                // console.log(json);
                if(json.error || json.message) {
                    throw new Error(json.message);
                } else {
                    dispatch(setActioned());
                    dispatch(deviceAddSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(resetActioned());
                dispatch(deviceAddFailed(err));
            });
    }
};

export const deviceUpdateRequest = () => {
    return {
        type: DEVICE_UPDATE_REQUEST
    }
};

export const deviceUpdateSuccess = (payload) => {
    return {
        type: DEVICE_UPDATE_SUCCESS,
        payload
    }
};

export const deviceUpdate = (payload) => {
    return (dispatch) => {
        dispatch(deviceUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/update`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceUpdateSuccess(json))
            });
    }
};

export const deviceSelectAllRequest = () => {
    return {
        type: DEVICES_SELECT_ALL_REQUEST
    }
};

export const deviceSelectAllSuccess = (payload) => {
    return {
        type: DEVICES_SELECT_ALL_SUCCESS,
        payload
    }
};

export const deviceSelectAllFailed = (payload) => {
    return {
        type: DEVICES_SELECT_ALL_FAILURE,
        payload
    }
};

export const deviceSelectAll = (payload) => {
    return (dispatch) => {
        const searchString = queryBuilder({selectType: payload.selectType, ...payload.query});

        dispatch(deviceSelectAllRequest());

        return fetch(`${ZZZAPI}/api/selectall${searchString}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload.body)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceSelectAllSuccess(json))
            })
            .catch(err => dispatch(deviceSelectAllFailed(err.message)));
    }
}

export const deviceSetTamper = (payload) => {
    return (dispatch) => {
        dispatch(deviceUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/tamper`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceUpdateSuccess(json))
            });
    }
};

export const deviceSetOperMode = (payload) => {
    return (dispatch) => {
        dispatch(deviceUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/operatingmode`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceUpdateSuccess(json))
            });
    }
};

export const deviceGroupUpdate = (payload) => {
    return (dispatch) => {
        dispatch(deviceUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/group`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceUpdateSuccess(json))
            });
    }
};

export const deviceRemoveRequest = () => {
    return {
        type: DEVICE_REMOVE_REQUEST
    }
};

export const deviceRemoveSuccess = (payload) => {
    return {
        type: DEVICE_REMOVE_SUCCESS,
        payload
    }
};

export const deviceRemoveFailure = (payload) => {
    return {
        type: DEVICE_REMOVE_FAILURE,
        payload
    }
};

export const deviceRemove = (payload) => {
    return (dispatch) => {
        dispatch(deviceRemoveRequest());

        return fetch(`${ZZZAPI}/api/devices/reset`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceRemoveSuccess(json))
            })
            .catch(err => dispatch(deviceRemoveFailure(err.message)));
    }
};

export const wakeupTimesUpdateRequest = () => {
    return {
        type: DEVICES_WAKEUPTIMES_UPDATE_REQUEST
    }
};

export const wakeupTimesUpdateSuccess = (payload) => {
    return {
        type: DEVICES_WAKEUPTIMES_UPDATE_SUCCESS,
        payload
    }

};

export const wakeupTimesUpdate = (payload) => {
    return (dispatch) => {
        dispatch(wakeupTimesUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/wakeuptimes`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(wakeupTimesUpdateSuccess(json));
            });
    }
};

export const wakeupDaysUpdateRequest = () => {
    return {
        type: DEVICES_WAKEUPDAYS_UPDATE_REQUEST
    }
};

export const wakeupDaysUpdateFailure = (payload) => {
    return {
        type: DEVICES_WAKEUPDAYS_UPDATE_FAILURE,
        payload
    }
};

export const wakeupDaysUpdateSuccess = (payload) => {
    return {
        type: DEVICES_WAKEUPDAYS_UPDATE_SUCCESS,
        payload
    }
};

export const wakeupDaysUpdate = (payload) => {
    return (dispatch) => {
        dispatch(wakeupDaysUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/wakeupdays`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(wakeupDaysUpdateSuccess(json));
            })
            .catch(err => dispatch(wakeupDaysUpdateFailure(err.message)));
    }
};

export const clearAlertRequest = () => {
    return {
        type: DEVICES_CLEARALERT_REQUEST
    }
};

export const clearAlertFailure = (payload) => {
    return {
        type: DEVICES_CLEARALERT_FAILURE,
        payload
    }
};

export const clearAlertSuccess = (payload) => {
    return {
        type: DEVICES_CLEARALERT_SUCCESS,
        payload
    }
};

export const clearDeviceAlert = (payload) => {
    return (dispatch) => {
        dispatch(clearAlertRequest());

        return fetch(`${ZZZAPI}/api/devices/clearalert`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                // dispatch(setActioned());
                dispatch(clearAlertSuccess(json));
            })
            .catch(err => dispatch(clearAlertFailure(err.message)));
    }
};

export const setFavouriteRequest = () => {
    return {
        type: DEVICES_SET_FAVOURITE_REQUEST
    }
};

export const setFavouriteFailure = (payload) => {
    return {
        type: DEVICES_SET_FAVOURITE_FAILURE,
        payload
    }
};

export const setFavouriteSuccess = (payload) => {
    return {
        type: DEVICES_SET_FAVOURITE_SUCCESS,
        payload
    }
};

export const deviceSetFavourite = (payload) => {
    return (dispatch) => {
        dispatch(setFavouriteRequest());

        return fetch(`${ZZZAPI}/api/devices/favourite`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                // dispatch(setActioned());
                dispatch(setFavouriteSuccess(json));
            })
            .catch(err => dispatch(setFavouriteFailure(err.message)));
    }
};

export const locationsGetRequest = () => {
    return {
        type: LOCATIONS_GET_REQUEST
    }
};

export const locationsGetSuccess = (payload) => {
    return {
        type: LOCATIONS_GET_SUCCESS,
        payload
    }
};

export const locationsGetFailure = (payload) => {
    return {
        type: LOCATIONS_GET_FAILURE,
        payload
    }
};

export const locationsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(locationsGetRequest());

        const queryParams = Object.keys(payload);
        let queryString = '';

        if(queryParams.length > 0) {
            queryString += '?';
            queryParams.forEach(query => {
                if(queryString !== '?') queryString += '&';
                queryString += `${query}=${payload[query]}`
            })
        }

        return fetch(`${ZZZAPI}/api/locations${queryString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(locationsGetSuccess(json))
            })
            .catch(err => dispatch(locationsGetFailure(err.message)));
    }
};

export const lastLocationsGetRequest = () => {
    return {
        type: LAST_LOCATIONS_GET_REQUEST
    }
};

export const lastLocationsGetSuccess = (payload) => {
    return {
        type: LAST_LOCATIONS_GET_SUCCESS,
        payload
    }
};

export const lastLocationsGetFailure = (payload) => {
    return {
        type: LAST_LOCATIONS_GET_FAILURE,
        payload
    }
};

export const lastLocationsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(lastLocationsGetRequest());

        const queryParams = Object.keys(payload);
        let queryString = '';

        if(queryParams.length > 0) {
            queryString += '?';
            queryParams.forEach(query => {
                if(queryString !== '?') queryString += '&';
                queryString += `${query}=${payload[query]}`
            })
        }

        return fetch(`${ZZZAPI}/api/lastlocations${queryString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(lastLocationsGetSuccess(json))
            })
            .catch(err => dispatch(lastLocationsGetFailure(err.message)));
    }
};

export const addressGetRequest = () => {
    return {
        type: ADDRESS_GET_REQUEST
    }
};

export const addressGetFailure = (payload) => {
    return {
        type: ADDRESS_GET_FAILURE,
        payload
    }
};
export const addressGetSuccess = (payload) => {
    return {
        type: ADDRESS_GET_SUCCESS,
        payload
    }
};

export const addressGet = (payload) => {
    return (dispatch) => {
        dispatch(addressGetRequest());

        return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${payload.latitude},${payload.longitude}&key=${GOOGLE_API_KEY}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(addressGetSuccess({ response: json, _id: payload._id}));
            })
            .catch(err => {
                console.log(err);
                dispatch(addressGetFailure(err))
            })
    }
};

export const lastWakeupsGetRequest = () => {
    return {
        type: LAST_WAKEUPS_GET_REQUEST
    }
};

export const lastWakeupsGetSuccess = (payload) => {
    return {
        type: LAST_WAKEUPS_GET_SUCCESS,
        payload
    }
};

export const lastWakeupsGetFailure = (payload) => {
    return {
        type: LAST_WAKEUPS_GET_FAILURE,
        payload
    }
};

export const lastWakeupsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(lastWakeupsGetRequest());

        return fetch(`${ZZZAPI}/api/lastwakeups`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(lastWakeupsGetSuccess(json))
            })
            .catch(err => dispatch(lastWakeupsGetFailure(err.message)));
    }
};

export const clearDeviceErrors = () => {
    return {
        type: CLEAR_DEVICE_ERRORS
    }
};


export const deviceRecoveryRequest = () => {
    return {
        type: DEVICE_GET_RECOVERY_CODE_REQUEST
    }
};

export const deviceRecoverySuccess = (payload) => {
    return {
        type: DEVICE_GET_RECOVERY_CODE_SUCCESS,
        payload
    }
};

export const deviceRecoveryFailure = (payload) => {
    return {
        type: DEVICE_GET_RECOVERY_CODE_FAILURE,
        payload
    }
};

export const deviceRecovery = (payload) => {
    return (dispatch) => {
        dispatch(deviceRecoveryRequest());

        return fetch(`${ZZZAPI}/api/devices/recoverycode`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceRecoverySuccess(json));
            })
            .catch(err => dispatch(deviceRecoveryFailure(err.message)));
    }
};


export const deviceClearRecoveryRequest = () => {
    return {
        type: DEVICE_CLEAR_RECOVERY_CODE_REQUEST
    }
};

export const deviceClearRecoverySuccess = (payload) => {
    return {
        type: DEVICE_CLEAR_RECOVERY_CODE_SUCCESS,
        payload
    }
};

export const deviceClearRecoveryFailure = (payload) => {
    return {
        type: DEVICE_GET_RECOVERY_CODE_FAILURE,
        payload
    }
};

export const deviceClearRecovery = (payload) => {
    return (dispatch) => {
        dispatch(deviceClearRecoveryRequest());

        return fetch(`${ZZZAPI}/api/devices/clearrecoverycode`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(deviceClearRecoverySuccess(json))
            })
            .catch(err => dispatch(deviceClearRecoveryFailure(err.message)));
    }
};


export const installationHistoryGetRequest = () => {
    return {
        type: DEVICES_GET_INSTALLATION_HISTORY_REQUEST
    }
};

export const installationHistoryGetSuccess = (payload) => {
    return {
        type: DEVICES_GET_INSTALLATION_HISTORY_SUCCESS,
        payload
    }
};

export const installationHistoryGetFailure = (payload) => {
    return {
        type: DEVICES_GET_INSTALLATION_HISTORY_FAILURE,
        payload
    }
};

export const installationHistoryGet = (payload) => {
    return (dispatch) => {
        dispatch(installationHistoryGetRequest());

        return fetch(`${ZZZAPI}/api/devices/installationhistory?deviceid=${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(installationHistoryGetSuccess(json))
            })
            .catch(err => dispatch(installationHistoryGetFailure(err.message)));
    }
};

export const notesGetRequest = () => {
    return {
        type: DEVICES_GET_NOTES_REQUEST
    }
};

export const notesGetSuccess = (payload) => {
    return {
        type: DEVICES_GET_NOTES_SUCCESS,
        payload
    }
};

export const notesGetFailure = (payload) => {
    return {
        type: DEVICES_GET_NOTES_FAILURE,
        payload
    }
};

export const notesGet = (payload) => {
    return (dispatch) => {
        dispatch(notesGetRequest());

        return fetch(`${ZZZAPI}/api/devices/notes?deviceid=${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(notesGetSuccess(json))
            })
            .catch(err => dispatch(notesGetFailure(err.message)));
    }
};

export const noteSetRequest = () => {
    return {
        type: DEVICES_SET_NOTE_REQUEST
    }
};

export const noteSetSuccess = (payload) => {
    return {
        type: DEVICES_SET_NOTE_SUCCESS,
        payload
    }
};

export const noteSetFailure = (payload) => {
    return {
        type: DEVICES_SET_NOTE_FAILURE,
        payload
    }
};

export const noteSet = (payload) => {
    return (dispatch) => {
        dispatch(noteSetRequest());

        return fetch(`${ZZZAPI}/api/devices/note`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(noteSetSuccess(json));
                dispatch(notesGet(payload.deviceId));
            })
            .catch(err => dispatch(noteSetFailure(err.message)));
    }
};

export const deviceTagsUpdateRequest = () => {
    return {
        type: UPDATE_DEVICE_TAGS_REQUEST
    }
};

export const deviceTagsUpdateSuccess = (payload) => {
    return {
        type: UPDATE_DEVICE_TAGS_SUCCESS,
        payload
    }
};

export const deviceTagsUpdateFailure = (payload) => {
    return {
        type: UPDATE_DEVICE_TAGS_FAILURE,
        payload
    }
};

export const deviceTagsUpdate = (payload) => {
    return (dispatch) => {
        dispatch(deviceTagsUpdateRequest());

        return fetch(`${ZZZAPI}/api/devices/tags`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(setActioned());
                dispatch(deviceTagsUpdateSuccess(json))
            })
            .catch(err => dispatch(deviceTagsUpdateFailure(err.message)));
    }
};

export const filterCountRequest = () => {
    return {
        type: COUNT_FILTER_REQUEST
    }
};

export const filterCountSuccess = (payload) => {
    return {
        type: COUNT_FILTER_SUCCESS,
        payload
    }
};

export const filterCountFailure = (payload) => {
    return {
        type: COUNT_FILTER_FAILURE,
        payload
    }
};

export const filterCount = () => {
    return (dispatch) => {
        dispatch(filterCountRequest());

        return fetch(`${ZZZAPI}/api/devices/filters/count`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(filterCountSuccess(json))
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(filterCountFailure(err));
            });
    }
};


export const proximityGetRequest = () => {
    return {
        type: PROXIMITY_GET_REQUEST
    }
};

export const proximityGetSuccess = (payload) => {
    return {
        type: PROXIMITY_GET_SUCCESS,
        payload
    }
};

export const proximityGetFailure = (payload) => {
    return {
        type: PROXIMITY_GET_FAILURE,
        payload
    }
};

export const proximityGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(proximityGetRequest());

        return fetch(`${ZZZAPI}/api/wakehistory/proximity`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(proximityGetSuccess(json))
            })
            .catch(err => dispatch(proximityGetFailure(err.message)));
    }
};
