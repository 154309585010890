import React, {useLayoutEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Divider,
    Header,
    Grid,
    Button,
    List, Dropdown, Pagination, Input,
} from 'semantic-ui-react';
import {childrenGet, userGet, userInvite, clearError, deleteUser, childUpdate} from "../../actions/user";
import {rolesGet} from '../../actions/auth';
import {resetActioned} from '../../actions/ui';
import {userGroupAdd, groupsGet, userGroupsGet, groupCreate, setPersistedGroups} from "../../actions/groups";
import InviteUser from '../invite-user';
import ItemsList from '../items-list';
import BaseModal from '../base-modal';
import EmptyBoard from '../empty-board';
import AddToGroup from "../add-to-group";
import AddToRole from "../add-to-role";
import RemoveItem from "../remove-item";
import {getLocaleString} from "../../languages/localised";
import {isProtected} from "../../utils/protected-component/protected-component";
import ProtectedComponent from "../../utils/protected-component";

const ManageAppUsers = (props) => {
    const limit = 20;
    let [modals, setModal] = useState({});
    let [searchValue, setSearchValue] = useState('');
    const [pagination, setPagination] = useState({skip: 0, limit: limit});
    const [currentPage, setCurrentPage] = useState(1);
    // const [groupsQuery, setGroupsQuery] = useState([]);
    let [sortByValue, setSortByValue] = useState(null);
    let [sortValues, setSortValues] = useState({
        'firstName': {
            name: 'firstName',
            direction: 'asc'
        },
        'lastName': {
            name: 'lastName',
            direction: 'asc'
        },
        'roles': {
            name: 'roles',
            direction: 'asc'
        },
        'email': {
            name: 'email',
            direction: 'asc'
        },
        'token': {
            name: 'token',
            direction: 'asc'
        }
    });
    const [selectedSort, setSelectedSort] = useState(null);

    useLayoutEffect(() => {
        props.userGet();
        const initialQuery = {skip: pagination.skip, limit: pagination.limit};
        if(props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
            initialQuery.groups = props.groups.persistedGroups;
        }
        props.childrenGet(initialQuery);
        props.groupsGet(false);
        props.getRoles();
    }, []);

    const clearSearch = () => {
        setCurrentPage(1);

        const paginationQuery = {skip: 0, limit: limit};
        setPagination(paginationQuery);
        setSearchValue('');
        if(props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
            paginationQuery.groups = props.groups.persistedGroups;
        }
        props.childrenGet(paginationQuery);
    };

    const change = (e) => {
        setSearchValue(e.target.value);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitNewSearch(null, props.groups.persistedGroups);
        }
    };

    const renderPagination = props.user && props.user.childrenData.documentCount && props.user.childrenData.documentCount && props.user.childrenData.documentCount >= limit ? (<Container textAlign="right">
                    <span style={{marginRight: 20}}>
                        {pagination.skip + 1}-{pagination.skip + pagination.limit < props.user.childrenData.documentCount ?
                        pagination.skip + pagination.limit
                        : props.user.childrenData.documentCount } of {props.user.childrenData.documentCount || 0}
                    </span>
        <Pagination
            style={{marginBottom: 15}}
            boundaryRange={1}
            activePage={currentPage}
            ellipsisItem={null}
            firstItem={undefined}
            lastItem={undefined}
            prevItem={undefined}
            nextItem={undefined}
            siblingRange={1}
            totalPages={Math.ceil(props.user.childrenData.documentCount / pagination.limit)}
            onPageChange={(e, {activePage}) => {
                setCurrentPage(activePage);

                const paginationQuery = {
                    skip: ((activePage - 1) * limit), limit: limit, sortByValue: sortByValue, search: searchValue
                };

                if(props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
                    paginationQuery.groups = props.groups.persistedGroups;
                }

                props.childrenGet(paginationQuery);
            }}
        />
    </Container>) : null;

    const executeAction = data => {
        if (data.action && data.action === 'groups') {
            props.userGroupsGet(data._id);
            setModal({...modals, [data.action]: true, data});
        }

        if (data.action && data.action === 'roles') {
            // props.userGroupsGet(data._id);
            setModal({...modals, [data.action]: true, data});
        }

        if (data.action && data.action === 'delete') {
            // props.userGroupsGet(data._id);
            setModal({...modals, [data.action]: true, data});
        }
    };

    const onCloseModal = () => {
        props.clearErrors();
        props.resetActioned();
        setModal({});
    };

    const combineName = (data) => {
        return(<>
            <p>
                <span style={{fontWeight: 'bold'}}>{data[0] || <span>&nbsp;</span>} {data[1] || <span>&nbsp;</span>}</span><br/>
                <span style={{color: 'rgb(100, 107, 133)'}}>{`${data[2]}`}</span>
            </p>
        </>);
    }

    const displayRoles = (data) => {
        const roles = data.filter(role => role && role.match(/^[0-9a-fA-F]{24}$/));
        // const options = props?.auth?.roles?.map(role => ({key: role.role, text: role.name, value: role.role}));
        // if(options) options.push({key: 'custom', text: 'Manage Roles', value: 'custom', onClick: () => console.log('CUSTOM!')});

        const activeRole = props?.auth?.roles?.find(role => role._id === roles[0]);
        // return options && (options.length-1) > 0 ? <Dropdown options={options} value={selected?.value} text={selected?.text || "Select Role..."} /> : <Button>Create Role</Button>;
        return activeRole ? <span style={{fontWeight: 'bold', color: 'rgb(100, 107, 133)'}}>{activeRole.name}</span> : <>No Role Selected</>;

    }

    const getStatus = (data) => {
        const colors = {
            'Active': 'green',
            'Pending': 'orange'
        };

        const status = data ? 'Active' : 'Pending';

        return <Button icon color={colors[status]} compact size='mini'>{getLocaleString(status)}</Button>;
    };

    const protectedMenu = (key) => {
        const protectedOptions = {
            groups: {
                permissions: ['users.app_user_groups.can_use'],
                groups: ['local_admin', 'admin']
            },
            roles: {
                permissions: ['users.roles.can_use'],
                groups: ['local_admin', 'admin']
            },
            delete: {
                permissions: ['users.app_user_delete.can_use'],
                groups: ['local_admin', 'admin']
            },

        };

        return isProtected(key, props.user, protectedOptions);
    }

    const onSort = (sortBy, selected) => {
        setCurrentPage(1);

        const direction = sortValues[sortBy].direction === 'asc' ? 'desc' : 'asc';
        setSelectedSort(selected);
        setSortValues({...sortValues, [sortBy]: {...sortValues[sortBy], direction}});
        setSortByValue({sortBy, direction});

        const paginationQuery = {skip: 0, limit: pagination.limit, sortByValue: {sortBy, direction}, search: searchValue};

        if(props.groups.persistedGroups && props.groups.persistedGroups.length > 0) {
            paginationQuery.groups = props.groups.persistedGroups;
        }

        props.childrenGet(paginationQuery);
    };

    const submitNewSearch = (sortValue, groups = []) => {
        setCurrentPage(1);
        submitSearch(sortValue, {skip: 0, limit: limit}, groups);
    }

    const submitSearch = (sortValue, paginate, groups) => {
        if (sortValue) setSortByValue(sortValue);
        if (paginate) setPagination(paginate);
        // if (groups) setGroupsQuery(groups);
        if (groups) props.groupsSetPersisted(groups);

        const query = {
            search: searchValue,
            sort: sortValue || sortByValue,
            override: 1
        };

        if (groups && groups.length) {
            query.groups = groups.join(',');
        } else if (!groups && props.groups.persistedGroups && props.groups.persistedGroups.length) {
            query.groups = props.groups.persistedGroups.join(',');
        } else {
            query.groups = "";
        }

        const getPaginationPoint = () => {
            const paginationPoint = {skip: 0, limit: limit};

            if (paginate && (!Number.isNaN(paginate.skip) && !Number.isNaN(paginate.limit))) {
                return paginate;
            }

            if (pagination && (!Number.isNaN(pagination.skip) && !Number.isNaN(pagination.limit))) {
                return pagination;
            }

            return paginationPoint;
        }

        const paginationPoint = getPaginationPoint();
        query.skip = paginationPoint.skip;
        query.limit = paginationPoint.limit;

        props.childrenGet(query);
    };

    return (
        <Container>
            {/*-- Devices Admin --*/}
            <Container>
                {(searchValue && props.user.children && props.user.children.length === 0) || props.user.children && props.user.children.length > null ?
                    <Grid columns={2} padded>
                        <Grid.Column>
                            <Container style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 5}} fluid>
                                <div style={{width: '100%', maxWidth: 269, zIndex: 1000}}>
                                    <Dropdown
                                        fluid
                                        placeholder='Group'
                                        selection
                                        clearable
                                        multiple
                                        value={props.groups.persistedGroups}
                                        onChange={(e, {value}) => {
                                            setCurrentPage(1);
                                            setPagination({skip: 0, limit: pagination.limit})
                                            // change({groups: value}, true);
                                            submitNewSearch(sortByValue, value);
                                        }}
                                        options={props.groups.groups.map(group => ({
                                            key: group._id,
                                            value: group._id,
                                            text: group.name
                                        }))}
                                    />
                                </div>
                            </Container>
                            <Container fluid>
                                <Input
                                    type='text'
                                    placeholder='Search Users...'
                                    icon={searchValue ? {name: 'cancel', link: true, onClick: clearSearch} : null}
                                    style={{
                                        marginTop: -2,
                                        marginRight: 5,
                                    }}
                                    value={searchValue}
                                    onChange={change}
                                    onKeyDown={onKeyDown}
                                />
                                <Button color='black' content='Search' onClick={() => submitNewSearch(null, props.groups.persistedGroups)}/>
                            </Container>
                        </Grid.Column>
                        <Grid.Column>
                            <Container fluid textAlign='right'>
                                <ProtectedComponent
                                    permissions={['users.invite_user.can_use']}
                                    groups={['local_admin', 'admin']}
                                >
                                    <Button
                                        color='black'
                                        content='Invite User'
                                        icon='add'
                                        labelPosition='right'
                                        onClick={() => {
                                            props.clearErrors();
                                            props.resetActioned();
                                            setModal({
                                                ...modals,
                                                add: true
                                            });
                                        }}
                                    />
                                </ProtectedComponent>
                            </Container>
                        </Grid.Column>
                    </Grid>
                        : null}

                <Divider hidden/>
                {renderPagination}

                {props.user.children && props.user.children.length > null ? <ItemsList
                    justify='left'
                    headings={[
                        // {
                        //     title: 'Name',
                        //     field: ['firstName', 'lastName', 'email'],
                        //     function: combineName
                        // },
                        {
                            title: 'First Name',
                            field: 'firstName',
                            sortName: 'firstName',
                            sortDirection: sortValues['firstName'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            sortName: 'lastName',
                            sortDirection: sortValues['lastName'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Email Address',
                            field: 'email',
                            sortName: 'email',
                            sortDirection: sortValues['email'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Role',
                            field: 'roles',
                            function: displayRoles,
                            sortName: 'roles',
                            sortDirection: sortValues['roles'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        },
                        {
                            title: 'Status',
                            field: 'firstName',
                            function: getStatus,
                            sortName: 'token',
                            sortDirection: sortValues['token'].direction,
                            sortFunc: onSort,
                            selected: selectedSort
                        }
                    ]}

                    items={props.user.children}
                    itemCount={props.user.childrenData.documentCount}

                    selectable

                    actions={[
                        // {
                        //     key: 'edit',
                        //     icon: 'edit',
                        //     text: 'Edit User Details',
                        //     color: 'teal',
                        //     action: 'edit'
                        // },
                        {
                            key: 'roles',
                            icon: 'id badge outline',
                            text: 'Manage User Role',
                            color: 'teal',
                            action: 'roles',
                            protected: protectedMenu,
                        },
                        {
                            key: 'groups',
                            icon: 'sitemap',
                            text: 'Manage Hierarchy',
                            color: 'teal',
                            action: 'groups',
                            selectable: true,
                            protected: protectedMenu,
                        },
                        {
                            key: 'delete',
                            icon: 'delete',
                            text: 'Remove User',
                            color: 'red',
                            action: 'delete',
                            selectable: false,
                            protected: protectedMenu,
                        }
                    ]}

                    callback={executeAction}
                /> : <EmptyBoard loader={props.user.isLoadingChildren} icon='users'
                                 message={searchValue ? 'No results.' : 'You haven&apos;t added any users.'} button={!searchValue ?

                    <Button
                        color='black'
                        content='Invite User'
                        icon='add'
                        labelPosition='right'
                        onClick={() => {
                            props.clearErrors();
                            props.resetActioned();
                            setModal({
                                ...modals,
                                add: true
                            });
                        }}
                        size='large'
                    /> : null
                }/>}

            </Container>
            <Divider hidden/>

            {renderPagination}

            <Divider hidden/>

            {/*<BaseModal color='red' content='Remove' icon='delete' labelPosition='left' title='Remove User' open={modals.delete} onClose={() => setModal({})}>DELETE</BaseModal>*/}

            <BaseModal
                color='black'
                content='Delete'
                icon='delete'
                labelPosition='left'
                title='Remove User'
                open={modals.delete}
                close={() => setModal({...modals, delete: false})}
                onClose={() => setModal({})}>
                {
                    modals.data ?
                        <RemoveItem
                            submit={props.deleteUser}
                            itemType={'user'}
                            itemNames={[`${modals.data.firstName} ${modals.data.lastName} - ${modals.data.email}`]}
                            removeItems={{userDeletionId: modals.data._id}}
                            icon='delete'
                            title='Delete User'
                            buttonText='Delete'
                            color='black'
                            loading={props.user.isDeletingUser}
                            close={() => {
                                setModal({...modals, delete: false});
                                // props.clearGroupErrors();
                            }}
                            error={props.user.error}
                        /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Group'
                icon='sitemap'
                labelPosition='left'
                title='Manage User Hierarchy'
                open={modals.groups}
                close={() => setModal({...modals, groups: false})}
                onClose={onCloseModal}>{
                modals.data && (props.groups.userGroups && props.groups.userGroups[modals.data._id]) ?
                    <AddToGroup
                        submit={props.userGroupAdd}
                        icon='edit'
                        buttonText='Save'
                        color='black'
                        _ids={Array.isArray(modals.data.payload) ? modals.data.payload.map(item => item._id) : [modals.data._id]}
                        groups={props.groups.groups}
                        groupCreate={props.groupCreate}
                        groupId={props.groups.userGroups[modals.data._id] || []}
                        merge
                        multiple
                        loading={props.groups.isSavingUserGroups}
                        close={() => {
                            setModal({...modals, groups: false});
                            // onCloseModal();
                        }}
                    /> : null}
            </BaseModal>

            <BaseModal
                color='black'
                content='Roles'
                icon='id badge'
                labelPosition='left'
                title='User Role'
                open={modals.roles}
                close={() => setModal({...modals, roles: false})}
                onClose={onCloseModal}>{
                modals.data ? <AddToRole
                    submit={props.childUserUpdate}
                    icon='edit'
                    buttonText='Save'
                    color='black'
                    data={modals.data}
                    roles={props.auth.roles}
                    loading={props.user.isUpdatingUser}
                    userId={modals?.data?._id}
                    close={() => {
                        setModal({...modals, roles: false});
                        // onCloseModal();
                    }}
                /> : null}
            </BaseModal>

            <BaseModal color='black' content='Invite User' icon='add' labelPosition='right' title='Invite User'
                       open={modals.add} close={() => setModal({...modals, add: false})} onClose={onCloseModal}>
                <InviteUser
                    submit={props.userAdd}
                    icon='add'
                    title='Invite User'
                    buttonText='Invite User'
                    color='black'
                    error={props.user.error}
                    loading={props.user.isInvitingUser}
                    close={() => {
                        setModal({...modals, add: false});
                        // onCloseModal();
                    }}
                />
            </BaseModal>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        childrenGet: (payload) => dispatch(childrenGet(payload)),
        userAdd: (payload) => dispatch(userInvite(payload)),
        userGet: () => dispatch(userGet()),
        clearErrors: () => dispatch(clearError()),
        userGroupAdd: (payload) => dispatch(userGroupAdd(payload)),
        childUserUpdate: (payload) => dispatch(childUpdate(payload)),
        groupsGet: (payload) => dispatch(groupsGet(payload)),
        userGroupsGet: (payload) => dispatch(userGroupsGet(payload)),
        groupCreate: (payload) => dispatch(groupCreate(payload)),
        resetActioned: () => dispatch(resetActioned()),
        deleteUser: (payload) => dispatch(deleteUser(payload)),
        groupsSetPersisted: (payload) => dispatch(setPersistedGroups(payload)),
        getRoles: () => dispatch(rolesGet())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAppUsers);
